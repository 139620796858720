import api from "../../api/axios";

export default async function getProdutosList() {
  try {
    const response = await api.get("products");

    if (response.status === 200) {
      const produtos = response.data.productsList;
      return { produtos };
    }
  } catch (error) {}
}

import { useContext, useEffect } from "react";
import { Row, Col, Button } from "antd";

import { Link } from "react-router-dom";
import logo from "../../assets/images/black-pay-logo.jpg";
import { UserOutlined } from "@ant-design/icons";
import { AuthContext } from "../../contexts/AuthContext";

const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];

function Header({ subName, onPress }) {
  const { user } = useContext(AuthContext);
  useEffect(() => window.scrollTo(0, 0));

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col
          span={12}
          md={12}
          style={{ display: "flex", alignItems: "center", gap: 10 }}
        >
          <img className="mobile-header-logo" src={logo} alt="blackpay" />
        </Col>
        <Col span={12} md={12} className="header-control">
          <Button
            type="link"
            className="sidebar-toggler"
            onClick={() => onPress()}
          >
            {toggler}
          </Button>

          <div className="btn-sign-in">
            <UserOutlined />
            <span>{user?.name}</span>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Header;

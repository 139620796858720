import { createContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../api/axios";

export const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const history = useHistory();
  const [user, setUser] = useState();
  const isAuthenticated = !!user;

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      api
        .get("me")
        .then((response) => {
          setUser(response.data.user);
        })
        .catch(() => {
          localStorage.removeItem("token");

          history.push("/");
        });
    } else {
      history.push("/");
    }
  }, []);

  async function signIn({ email, password }) {
    try {
      const { data } = await api.post("sessions", {
        email,
        password,
      });

      const token = data.token;
      const user = data.user;

      localStorage.setItem("token", token);

      setUser({ user });

      api.defaults.headers["Authorization"] = `Bearer ${token}`;
      history.push("/dashboard");
      window.location.reload();
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function logout() {
    try {
      localStorage.removeItem("token");
      setUser();
      history.push("/");
    } catch (error) {}
  }

  return (
    <AuthContext.Provider value={{ signIn, isAuthenticated, user, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

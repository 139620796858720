import {
  DeleteFilled,
  MoreOutlined,
  PlusOutlined,
  ExportOutlined,
  CopyOutlined,
  EditFilled,
} from "@ant-design/icons";
import {
  Card,
  Col,
  Dropdown,
  Input,
  Menu,
  Row,
  Table,
  Button as AntButton,
} from "antd";
import Button from "../../components/Button";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import getProdutosList from "../../services/produtos/getProdutosList.service";
import { copyToClipboard } from "../../utils/clipboard";
import apagarProduto from "../../services/produtos/apagarProduto.service";
import getLojasList from "../../services/lojas/getLojasList.service";
import toast from "react-hot-toast";
import EmptyPlaceholder from "../../components/EmptyPlaceholder";

export default function Produtos() {
  const history = useHistory();
  const [lojas, setLojas] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [isLoadingGetLojas, setIsLoadingGetLojas] = useState(false);
  const [isLoadingGetProdutos, setIsLoadingGetProdutos] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredProdutos, setFilteredProdutos] = useState([]);

  useEffect(() => {
    getLojas();
    getProdutos();
  }, []);

  async function getLojas() {
    try {
      setIsLoadingGetLojas(true);
      const { lojas } = await getLojasList();
      setLojas(lojas);
    } catch (error) {
    } finally {
      setIsLoadingGetLojas(false);
    }
  }

  async function getProdutos() {
    try {
      setIsLoadingGetProdutos(true);
      const { produtos } = await getProdutosList();
      setProdutos(produtos);
    } catch (error) {
    } finally {
      setIsLoadingGetProdutos(false);
    }
  }

  function handleSearch(text) {
    setSearchText(text);
    const filtered = produtos.filter((produto) =>
      produto.label.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredProdutos(filtered);
  }

  function handleNavigateToAdicionarProduto() {
    history.push("/adicionar-produto");
    setSearchText("");
  }

  function handleGoToAdicionarLoja() {
    history.push("/adicionar-loja");
  }

  async function handleApagarProduto(id) {
    try {
      console.log(id);
      setIsLoadingGetProdutos(true);
      const response = await apagarProduto(id);
      if (response === "ok") {
        toast.success("Produto apagado com sucesso!");
      }
    } catch (error) {
      console.log(error);
      toast.error("Não foi possível apagar este produto!");
    } finally {
      getProdutos();
    }
  }

  const columns = [
    {
      title: "Produto",
      align: "left",
      key: "label",

      render: (data) => (
        <div>
          <img
            src={data?.image}
            style={{ borderRadius: 8, maxWidth: 100 }}
            alt="logo"
          />
          &nbsp; &nbsp;
          <strong title={data?.label}>{data?.label}</strong>
        </div>
      ),
    },
    {
      title: "Preço",
      align: "left",
      key: "price",
      dataIndex: "price",
      render: (data) =>
        parseFloat(data).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
    },
    {
      title: "Loja",
      align: "left",
      key: "store",
      dataIndex: "store",
      render: (data) => {
        console.log(data);
        return (
          <div>
            <img
              src={data?.logo_image}
              style={{ borderRadius: 8, maxWidth: 100 }}
              alt="loja"
            />
            &nbsp;&nbsp;
            <span>{data?.label}</span>
          </div>
        );
      },
    },
    {
      title: "Pedidos",
      key: "pedidos",
      align: "left",
      dataIndex: "orders",
      render: (data) => data?.length,
    },
    {
      title: "Acessos",
      key: "accesses",
      align: "left",
      dataIndex: "Visit",
      render: (data) => data?.length,
    },
    {
      title: "Ações",
      key: "actions",
      align: "right",
      render: (data) => {
        return (
          <Dropdown
            arrow
            trigger="click"
            overlay={
              <Menu>
                <Menu.Item
                  onClick={() =>
                    window.open(
                      `${data?.store?.site_url}/checkout/p/${data?.id}`,
                      "_blank" // <- This is what makes it open in a new window.
                    )
                  }
                  style={{ fontSize: 18, padding: "12px" }}
                  icon={<ExportOutlined style={{ fontSize: 18 }} />}
                >
                  Abrir
                </Menu.Item>

                <Menu.Item
                  onClick={() =>
                    copyToClipboard(
                      `${data?.store?.site_url}/checkout/p/${data?.id}`
                    )
                  }
                  style={{ fontSize: 18, padding: "12px" }}
                  icon={<CopyOutlined style={{ fontSize: 18 }} />}
                >
                  Copiar link
                </Menu.Item>

                <Menu.Item
                  onClick={() => history.push(`/editar-produto/${data?.id}`)}
                  style={{ fontSize: 18, padding: "12px" }}
                  icon={<EditFilled style={{ fontSize: 18 }} />}
                >
                  Editar
                </Menu.Item>

                <Menu.Item
                  onClick={() => handleApagarProduto(data?.id)}
                  style={{ fontSize: 18, padding: "12px" }}
                  danger
                  icon={<DeleteFilled style={{ fontSize: 18 }} />}
                >
                  Apagar
                </Menu.Item>
              </Menu>
            }
          >
            <AntButton
              // type="primary"
              icon={<MoreOutlined style={{ fontSize: 20 }} />}
              style={{ padding: "4px 5px" }}
            />
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <div
        className="layout-content mt-25"
        style={{ maxWidth: 1200, margin: "0 auto" }}
      >
        <Row gutter={[24, 0]}>
          <Row
            gutter={[24, 0]}
            style={{
              padding: "12px",
              marginBottom: "24px",
              width: "100%",
            }}
          >
            <Col>
              <strong style={{ fontSize: 24, color: "#333" }}>Produtos</strong>
            </Col>

            <Col>
              <Input
                disabled={
                  isLoadingGetLojas || isLoadingGetProdutos || lojas.length <= 0
                }
                placeholder="Pesquisar produtos..."
                value={searchText}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </Col>

            <Col>
              <Button
                disabled={
                  isLoadingGetLojas || isLoadingGetProdutos || lojas.length <= 0
                }
                type="primary"
                onClick={handleNavigateToAdicionarProduto}
                icon={<PlusOutlined />}
              >
                Adicionar produto
              </Button>
            </Col>
          </Row>
          <Col xs="24" xl={24}>
            {lojas.length === 0 && (
              <EmptyPlaceholder
                title="Ops, você ainda não adicionou nenhuma loja..."
                text="Para adicionar um produto, é necessário primeiro criar uma loja para
                  associá-lo a ela."
                buttonClick={handleGoToAdicionarLoja}
                buttonText="Adicionar minha primeira loja"
              />
            )}

            {lojas.length > 0 && produtos.length === 0 && (
              <EmptyPlaceholder
                title="Ops, você ainda não adicionou nenhum produto..."
                text="Clique no botão abaixo para adicionar"
                buttonClick={handleNavigateToAdicionarProduto}
                buttonText="Adicionar meu primeiro produto"
              />
            )}

            {lojas.length > 0 && produtos.length > 0 && (
              <Card bordered={false} className="criclebox tablespace mb-24">
                <div className="table-responsive">
                  <Table
                    loading={isLoadingGetProdutos}
                    columns={columns}
                    dataSource={searchText ? filteredProdutos : produtos}
                    pagination={{
                      pageSize: 7,
                      position: ["bottomRight"],
                      showSizeChanger: false,
                    }}
                    className="ant-border-space"
                  />
                </div>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}

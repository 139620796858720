import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, Col, Row, Steps, Tooltip } from "antd";
import {
  BgColorsOutlined,
  DollarCircleOutlined,
  FileDoneOutlined,
  ArrowLeftOutlined,
  LoadingOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import EstilosDoCheckout from "./layout/EstilosDoCheckout";
import ConfigurarEmail from "./layout/ConfigurarEmail";
import FormasDePagamento from "./layout/FormasDePagamento";
import InformacoesGerais from "./layout/InformacoesGerais";
import Resumo from "./layout/Resumo";

export default function AdicionarLoja() {
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(0);
  const [newLojaInfo, setNewLojaInfo] = useState({
    label: "",
    site_url: "",
    logo_image: "",
  });
  const [isLoadingCreateLoja, setIsLoadingCreateLoja] = useState(false);

  const clearDataAndClose = () => {
    setNewLojaInfo({
      label: "",
      site_url: "",
      logo_image: "",
    });
    setCurrentStep(0);
    history.push("/lojas");
  };

  return (
    <>
      <div
        className="layout-content mt-25"
        style={{ maxWidth: 1200, margin: "0 auto", marginTop: 32 }}
      >
        <div
          style={{
            marginBottom: 24,
            display: "flex",
            alignItems: "center",
            gap: 24,
          }}
        >
          <Tooltip title="Voltar para lojas" placement="bottom">
            <small
              style={{
                cursor: "pointer",
                fontSize: 14,
                display: "flex",
                alignItems: "center",
                gap: 4,
              }}
              onClick={() => history.push("/lojas")}
            >
              <ArrowLeftOutlined />
              Lojas
            </small>
          </Tooltip>
          <strong style={{ fontSize: 24, color: "#333" }}>
            Adicionar loja
          </strong>
        </div>

        <Row gutter={[24, 24]} style={{ maxWidth: "1200px", margin: "0 auto" }}>
          <Col span={24}>
            <Card
              title={
                currentStep === 4 ? (
                  false
                ) : (
                  <Col span={24} className="mb-25">
                    <Steps
                      current={currentStep}
                      size="small"
                      direction="horizontal"
                      labelPlacement="vertical"
                    >
                      <Steps.Step
                        title="1. Informações Básicas"
                        icon={<SettingOutlined />}
                      />

                      {/* <Steps.Step
                        title="2. Formas de Pagamento"
                        icon={<DollarCircleOutlined />}
                      /> */}

                      <Steps.Step
                        title="3. Configurações de E-mail"
                        icon={<MailOutlined />}
                      />

                      <Steps.Step
                        title="4. Estilos do Checkout"
                        icon={<BgColorsOutlined />}
                      />

                      <Steps.Step
                        title={
                          isLoadingCreateLoja
                            ? "Adicionando loja..."
                            : "5. Resumo"
                        }
                        icon={
                          isLoadingCreateLoja ? (
                            <LoadingOutlined />
                          ) : (
                            <FileDoneOutlined />
                          )
                        }
                      />
                    </Steps>
                  </Col>
                )
              }
            >
              <Row gutter={[24, 24]}>
                {currentStep === 0 && (
                  <InformacoesGerais
                    newLojaInfo={newLojaInfo}
                    setNewLojaInfo={setNewLojaInfo}
                    handleCancelButtonClick={clearDataAndClose}
                    handleNextButtonClick={() => setCurrentStep(1)}
                  />
                )}

                {/* {currentStep === 1 && (
                  <FormasDePagamento
                    newLojaInfo={newLojaInfo}
                    setNewLojaInfo={setNewLojaInfo}
                    handleCancelButtonClick={clearDataAndClose}
                    handleClickBackButton={() => setCurrentStep(0)}
                    handleNextButtonClick={() => setCurrentStep(2)}
                  />
                )} */}

                {currentStep === 1 && (
                  <ConfigurarEmail
                    newLojaInfo={newLojaInfo}
                    setNewLojaInfo={setNewLojaInfo}
                    handleCancelButtonClick={clearDataAndClose}
                    handleClickBackButton={() => setCurrentStep(1)}
                    handleNextButtonClick={() => setCurrentStep(2)}
                  />
                )}

                {currentStep === 2 && (
                  <EstilosDoCheckout
                    newLojaInfo={newLojaInfo}
                    setNewLojaInfo={setNewLojaInfo}
                    handleCancelButtonClick={clearDataAndClose}
                    handleClickBackButton={() => setCurrentStep(2)}
                    handleNextButtonClick={() => setCurrentStep(3)}
                  />
                )}

                {currentStep === 3 && (
                  <Resumo
                    newLojaInfo={newLojaInfo}
                    setNewLojaInfo={setNewLojaInfo}
                    isLoadingCreateLoja={isLoadingCreateLoja}
                    setIsLoadingCreateLoja={setIsLoadingCreateLoja}
                  />
                )}
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

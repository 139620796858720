import api from "../../api/axios";

export default async function getPedidos({ page_index = 1, page_size = 10 }) {
  try {
    const response = await api.get("orders", {
      params: {
        page_index,
        page_size,
      },
    });

    if (response.status === 200) {
      const pedidos = response.data.ordersList;
      const totalCount = response.data.totalCount;
      return { pedidos, totalCount };
    }
  } catch (error) {}
}

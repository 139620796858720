export function copyToClipboard(text) {
  // Cria um elemento de área de texto temporário
  const tempTextArea = document.createElement("textarea");
  tempTextArea.value = text;

  // Adiciona o elemento temporário ao corpo do documento
  document.body.appendChild(tempTextArea);

  // Seleciona o texto dentro do elemento de área de texto
  tempTextArea.select();

  // Copia o texto para a área de transferência
  document.execCommand("copy");

  // Remove o elemento de área de texto temporário
  document.body.removeChild(tempTextArea);
}

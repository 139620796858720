import toast from "react-hot-toast";
import api from "../../api/axios";

export default async function getProductById(id) {
  try {
    const response = await api.get(`products/${id}`);

    const { product } = response.data;

    if (product) {
      return { product };
    }
  } catch (error) {
    console.logO(error);
    toast.error("Produto não encontrado!");
  }
}

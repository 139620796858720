import React from "react";
import { Button as AntdButton } from "antd";
import PropTypes from "prop-types";

export default function Button({
  type,
  size,
  children,
  fullWidth,
  disabled,
  ...rest
}) {
  return (
    <AntdButton
      {...rest}
      disabled={disabled}
      size={size}
      type={type}
      style={
        type === "primary"
          ? {
              background: disabled ? "#999" : "#222",
              borderColor: disabled ? "#999" : "#222",
              color: "#fff",
              width: fullWidth && "100%",
            }
          : {
              borderColor: disabled ? "#999" : "#222",
              color: "#222",
              width: fullWidth && "100%",
            }
      }
    >
      {children}
    </AntdButton>
  );
}

Button.propTypes = {
  type: PropTypes.oneOf([["default", "primary", "ghost", "dashed", "link"]]),
  size: PropTypes.oneOf(["small, medium", "large"]),
  children: PropTypes.any,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  type: "default",
  size: "medium",
  fullWidth: false,
  disabled: false,
};

import { DeleteFilled, MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Col, Dropdown, Input, Menu, Row, Table } from "antd";
import Button from "../../components/Button";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { formatarDataBR } from "../../utils/formatTime";
import getPixels from "../../services/pixels/getPixels.service";
import AdicionarPixel from "./layout/AdicionarPixel";
import apagarPixel from "../../services/pixels/apagarPixel.service";
import EmptyPlaceholder from "../../components/EmptyPlaceholder";

function Pixel() {
  const [pixels, setPixels] = useState([]);
  const [isLoadingGetPixels, setIsLoadingGetPixels] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredPixels, setFilteredPixels] = useState([]);
  const [isOpenCreatePixel, setIsOpenCreatePixel] = useState(false);

  useEffect(() => {
    handleGetPixels();
  }, []);

  const handleGetPixels = async () => {
    try {
      setIsLoadingGetPixels(true);
      const { pixelCodeList } = await getPixels();
      if (pixelCodeList) {
        setPixels(pixelCodeList);
      }
    } catch (error) {
    } finally {
      setIsLoadingGetPixels(false);
    }
  };

  function handleSearch(text) {
    setSearchText(text);
    const filtered = pixels.filter((produto) =>
      produto.code.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredPixels(filtered);
  }

  const handleOpenAdicionarPixel = () => {
    setIsOpenCreatePixel(true);
  };

  const handleDeletePixelCode = async (data) => {
    try {
      const response = await apagarPixel(data.id);

      if (response === "ok") {
        toast.success("Pixel removido com sucesso!");
        handleGetPixels();
      }
    } catch (error) {
      toast.error("Não foi possível remover este código pixel");
    }
  };

  const handleCloseCreatePixel = () => {
    setIsOpenCreatePixel(false);
    handleGetPixels();
  };

  const columns = [
    {
      title: "PRODUTO",
      dataIndex: "product",
      align: "center",
      key: "product",
      render: (data) => {
        return data.label;
      },
    },
    {
      title: "Código",
      align: "left",
      key: "code",
      render: (data) => {
        return <strong>{data.code}</strong>;
      },
    },
    {
      title: "ACCESS TOKEN",
      dataIndex: "access_token",
      align: "center",
      key: "access_token",
      render: (data) => {
        return <Input.Password value={data} readOnly bordered={false} />;
      },
    },
    {
      title: "DATA DE CRIAÇÃO",
      dataIndex: "created_at",
      align: "center",
      key: "created_at",
      render: (data) => {
        return formatarDataBR(data);
      },
    },
    {
      title: "AÇÕES",
      key: "actions",
      render: (data) => {
        return (
          <Dropdown
            trigger="click"
            overlay={
              <Menu>
                <Menu.Item
                  onClick={() => handleDeletePixelCode(data)}
                  danger
                  icon={<DeleteFilled style={{ fontSize: 18 }} />}
                >
                  Remover
                </Menu.Item>
              </Menu>
            }
          >
            <Button icon={<MoreOutlined />} style={{ padding: "0 8px" }} />
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <AdicionarPixel
        isOpen={isOpenCreatePixel}
        handleClose={handleCloseCreatePixel}
      />

      <div
        className="layout-content mt-25"
        style={{ maxWidth: 1200, margin: "0 auto" }}
      >
        <Row gutter={[24, 0]}>
          <Row
            gutter={[24, 0]}
            style={{
              padding: "12px",
              marginBottom: "24px",
              width: "100%",
            }}
          >
            <Col>
              <strong style={{ fontSize: 24, color: "#333" }}>
                Códigos Pixel
              </strong>
            </Col>

            <Col>
              <Input
                placeholder="Pesquisar pixel..."
                value={searchText}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </Col>

            <Col>
              <Button
                type="primary"
                onClick={handleOpenAdicionarPixel}
                icon={<PlusOutlined />}
              >
                Adicionar pixel
              </Button>
            </Col>
          </Row>
          <Col xs="24" xl={24}>
            {pixels.length === 0 && (
              <EmptyPlaceholder
                title="Ops, você ainda não adicionou nenhum código pixel..."
                text="Clique no botão abaixo para adicionar"
                buttonClick={handleOpenAdicionarPixel}
                buttonText="Adicionar meu primeiro código pixel"
              />
            )}

            {pixels.length > 0 && (
              <Card bordered={false} className="criclebox tablespace mb-24">
                <div className="table-responsive">
                  <Table
                    loading={isLoadingGetPixels}
                    columns={columns}
                    dataSource={searchText ? filteredPixels : pixels}
                    pagination={{
                      pageSize: 7,
                      position: ["bottomRight"],
                      showSizeChanger: false,
                    }}
                    className="ant-border-space"
                  />
                </div>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Pixel;

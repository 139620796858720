import api from "../../api/axios";

export default async function getPixels() {
  try {
    const response = await api.get("pixel-code");

    if (response.status === 200) {
      const pixelCodeList = response.data.pixelCodeList;
      return { pixelCodeList };
    }
  } catch (error) {}
}

import { Button, Menu } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/black-pay-logo.jpg";
import {
  AreaChartOutlined,
  FacebookFilled,
  InboxOutlined,
  LogoutOutlined,
  OrderedListOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");

  const { logout } = useContext(AuthContext);

  const rtl = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6C3 4.34315 4.34315 3 6 3H16C16.3788 3 16.725 3.214 16.8944 3.55279C17.0638 3.89157 17.0273 4.29698 16.8 4.6L14.25 8L16.8 11.4C17.0273 11.703 17.0638 12.1084 16.8944 12.4472C16.725 12.786 16.3788 13 16 13H6C5.44772 13 5 13.4477 5 14V17C5 17.5523 4.55228 18 4 18C3.44772 18 3 17.5523 3 17V6Z"
        fill={color}
      ></path>
    </svg>,
  ];

  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
        <span>
          Black
          <br />
          Pay
        </span>
      </div>

      <hr />
      <Menu theme="light" mode="inline">
        <Menu.Item key="1">
          <NavLink to="/dashboard">
            <span
              className="icon"
              style={{
                background: page === "dashboard" ? color : "",
              }}
            >
              <AreaChartOutlined style={{ fontSize: 22 }} />
            </span>
            <span className="label">Dashboard</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to="/pedidos">
            <span
              className="icon"
              style={{
                background: page === "pedidos" ? color : "",
              }}
            >
              <OrderedListOutlined style={{ fontSize: 22 }} />
            </span>
            <span className="label">Pedidos</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="3">
          <NavLink to="/produtos">
            <span
              className="icon"
              style={{
                background: page === "produtos" ? color : "",
              }}
            >
              <InboxOutlined style={{ fontSize: 22 }} />
            </span>
            <span className="label">Produtos</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="4">
          <NavLink to="/lojas">
            <span
              className="icon"
              style={{
                background: page === "lojas" ? color : "",
              }}
            >
              <ShopOutlined style={{ fontSize: 22 }} />
            </span>
            <span className="label">Lojas</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="5">
          <NavLink to="/pixel">
            <span
              className="icon"
              style={{
                background: page === "rtl" ? color : "",
              }}
            >
              <FacebookFilled style={{ fontSize: 22 }} />
            </span>
            <span className="label">Pixel</span>
          </NavLink>
        </Menu.Item>
        {/* <Menu.Item key="6">
          <NavLink to="/visitas">
            <span
              className="icon"
              style={{
                background: page === "rtl" ? color : "",
              }}
            >
              {rtl}
            </span>
            <span className="label">Visitas</span>
          </NavLink>
        </Menu.Item> */}
      </Menu>
      <div className="aside-footer">
        <Button
          onClick={logout}
          type="ghost"
          icon={<LogoutOutlined />}
          danger
          className="ant-btn-sm ant-btn-block"
        >
          Sair
        </Button>
      </div>
    </>
  );
}

export default Sidenav;

import api from "../../api/axios";

export default async function changePaymentStatus({
  payment_identifier,
  payment_status,
}) {
  try {
    const response = await api.patch("orders", {
      payment_identifier,
      payment_status,
    });

    console.log(response);

    if (response.status === 200) {
      return "ok";
    }
  } catch (error) {}
}

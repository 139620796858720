import { Col, Drawer, Row } from "antd";
import React from "react";

export default function OrderDetails({ isOpen, handleClose, order }) {
  return (
    <Drawer
      placement="right"
      width={"40%"}
      visible={isOpen}
      onClose={handleClose}
      title={`Detalhes do pedido: #${order?.payment_identifier}`}
    >
      <Row span={24}>
        <Col span={12}>
          <h3>Informações do comprador</h3>

          <p>
            Nome completo: <strong>{order?.buyer_full_name}</strong>
          </p>

          <p>
            CPF: <strong>{order?.buyer_cpf}</strong>
          </p>

          <p>
            E-mail: <strong>{order?.buyer_email}</strong>
          </p>

          <p>
            Telefone / Whatsapp: <strong>{order?.buyer_phone}</strong>
          </p>

          {order?.buyer_cep && (
            <p>
              CEP: <strong>{order?.buyer_cep}</strong>
            </p>
          )}

          {order?.buyer_state && (
            <p>
              Estado: <strong>{order?.buyer_state}</strong>
            </p>
          )}

          {order?.buyer_city && (
            <p>
              Cidade: <strong>{order?.buyer_city}</strong>
            </p>
          )}

          {order?.buyer_street && (
            <p>
              Rua: <strong>{order?.buyer_street}</strong>
            </p>
          )}

          {order?.buyer_house_number && (
            <p>
              Número da casa: <strong>{order?.buyer_house_number}</strong>
            </p>
          )}

          {order?.buyer_neighborhood && (
            <p>
              Bairro: <strong>{order?.buyer_neighborhood}</strong>
            </p>
          )}
        </Col>

        <Col span={12}>
          <h3>Informações do pagamento</h3>

          <p>
            Status:{" "}
            <strong>
              {" "}
              {order?.payment_status === "confirmed"
                ? "Confirmado"
                : "Pendente"}
            </strong>
          </p>

          <p>
            Produto: <strong>{order?.product?.label}</strong>
          </p>

          <p>
            Valor do produto:{" "}
            <strong>
              {parseFloat(order?.product?.price).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </strong>
          </p>

          <p>
            Quantidade: <strong>{order?.quantity}</strong>
          </p>

          <p>
            Forma de envio:{" "}
            <strong>
              {order?.selected_shipping_method?.label}
              {" > "}
              {parseFloat(
                order?.selected_shipping_method?.price
              ).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </strong>
          </p>
        </Col>
      </Row>
    </Drawer>
  );
}

import axios from "axios";

const token = localStorage.getItem("token");

export default axios.create({
  baseURL: "https://checkout-api-production.up.railway.app",
  // baseURL: "http://localhost:3333",
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

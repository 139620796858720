import api from "../../api/axios";

export default async function getLojasList() {
  try {
    const response = await api.get("stores");

    if (response.status === 200) {
      const lojas = response.data.storesList;
      return { lojas };
    }
  } catch (error) {}
}

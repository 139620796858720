import {
  BgColorsOutlined,
  DeleteFilled,
  DownloadOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Card, Col, Dropdown, Input, Menu, Row, Table } from "antd";
import Button from "../../components/Button";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DeleteStoreConfirmation from "./layout/DeleteStoreConfirmation";
import apagarLoja from "../../services/lojas/apagarLoja.service";
import toast from "react-hot-toast";
import TrocarChavePix from "./layout/TrocarChavePix";
import PixLogo from "../../assets/images/pix-logo.png";
import EstilosDoCheckout from "./layout/EstilosDoCheckout";
import getLojasList from "../../services/lojas/getLojasList.service";
import EmptyPlaceholder from "../../components/EmptyPlaceholder";

function Lojas() {
  const history = useHistory();
  const [isLoadingGetLojas, setIsLoadingGetLojas] = useState(false);
  const [lojas, setLojas] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredLojas, setFilteredLojas] = useState([]);
  const [isOpenDeleteStoreConfirmation, setIsOpenDeleteStoreConfirmation] =
    useState({ isOpen: false, store: null });
  const [isOpenChangePixKey, setIsOpenChangePixKey] = useState({
    isOpen: false,
    store: null,
  });
  const [isOpenUpdateCheckoutConfigs, setIsOpenUpdateCheckoutConfigs] =
    useState({
      isOpen: false,
      store: null,
    });
  const [isLoadingDeleteStore, setIsLoadingDeleteStore] = useState(false);

  useEffect(() => {
    getLojas();
  }, []);

  async function getLojas() {
    try {
      setIsLoadingGetLojas(true);
      const { lojas } = await getLojasList();
      setLojas(lojas);
    } catch (error) {
    } finally {
      setIsLoadingGetLojas(false);
    }
  }

  function handleSearch(text) {
    setSearchText(text);
    const filtered = lojas.filter((loja) =>
      loja.label.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredLojas(filtered);
  }

  function handleNavigateToAdicionarLoja() {
    history.push("/adicionar-loja");
    setSearchText("");
  }

  async function handleConfirmDelete() {
    try {
      setIsLoadingDeleteStore(true);
      const response = await apagarLoja(
        isOpenDeleteStoreConfirmation.store?.id
      );
      if (response === "ok") {
        setIsOpenDeleteStoreConfirmation({ isOpen: false, store: null });
        toast.success("Loja apagada");
        getLojas();
      }
    } catch (error) {
      toast.error("Erro ao tentar apagar esta loja");
    } finally {
      setIsLoadingDeleteStore(false);
    }
  }

  const downloadFile = () => {
    const link = document.createElement("a");
    link.href = "/checkout.zip";
    link.setAttribute("download", "checkout.zip");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const columns = [
    {
      title: "Loja",
      align: "left",
      key: "label",
      render: (data) => (
        <div>
          <img
            src={data?.logo_image}
            style={{ borderRadius: 8, maxWidth: 100 }}
            alt="loja"
          />
          &nbsp;&nbsp;
          <strong>{data.label}</strong>
        </div>
      ),
    },
    {
      title: "Total faturado",
      key: "total_orders",
      align: "right",
      render: (data) => {
        const totalOrders = data.orders.reduce(
          (acc, order) => acc + order.total_price,
          0
        );
        return totalOrders.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      title: "Pedidos",
      dataIndex: "orders",
      align: "right",
      key: "orders",
      render: (orders) => {
        return orders?.length;
      },
    },
    {
      title: "Ações",
      key: "actions",
      align: "right",
      render: (data) => {
        return (
          <Dropdown
            arrow
            trigger="click"
            overlay={
              <Menu>
                {/* <Menu.Item
                  onClick={() =>
                    setIsOpenChangePixKey({ isOpen: true, store: data })
                  }
                  style={{ fontSize: 18, padding: "12px" }}
                  icon={<img src={PixLogo} height={18} alt="pix" />}
                >
                  Gerenciar chave pix
                </Menu.Item> */}
                <Menu.Item
                  onClick={() =>
                    setIsOpenUpdateCheckoutConfigs({
                      isOpen: true,
                      store: data,
                    })
                  }
                  style={{ fontSize: 18, padding: "12px" }}
                  icon={<BgColorsOutlined style={{ fontSize: 18 }} />}
                >
                  Estilos do checkout
                </Menu.Item>
                <Menu.Item
                  onClick={downloadFile}
                  style={{ fontSize: 18, padding: "12px" }}
                  icon={<DownloadOutlined style={{ fontSize: 18 }} />}
                >
                  Baixar arquivo de integração
                </Menu.Item>
                <Menu.Item
                  onClick={() =>
                    setIsOpenDeleteStoreConfirmation({
                      isOpen: true,
                      store: data,
                    })
                  }
                  style={{ fontSize: 18, padding: "12px" }}
                  danger
                  icon={<DeleteFilled style={{ fontSize: 18 }} />}
                >
                  Apagar
                </Menu.Item>
              </Menu>
            }
          >
            <Button icon={<MoreOutlined />} style={{ padding: "0 8px" }} />
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <DeleteStoreConfirmation
        isOpen={isOpenDeleteStoreConfirmation.isOpen}
        handleClose={() => setIsOpenDeleteStoreConfirmation(false)}
        confirmDelete={handleConfirmDelete}
        store={isOpenDeleteStoreConfirmation.store}
        isLoadingDeleteStore={isLoadingDeleteStore}
      />

      <TrocarChavePix
        isOpen={isOpenChangePixKey.isOpen}
        store={isOpenChangePixKey.store}
        handleClose={() => {
          setIsOpenChangePixKey({ isOpen: false, store: null });
          getLojas();
        }}
      />

      <EstilosDoCheckout
        isOpen={isOpenUpdateCheckoutConfigs.isOpen}
        store={isOpenUpdateCheckoutConfigs.store}
        handleClose={() => {
          setIsOpenUpdateCheckoutConfigs({ isOpen: false, store: null });
          getLojas();
        }}
      />

      <div
        className="layout-content mt-25"
        style={{ maxWidth: 1200, margin: "0 auto" }}
      >
        <Row gutter={[24, 0]}>
          <Row
            gutter={[24, 0]}
            style={{
              padding: "12px",
              marginBottom: "24px",
              width: "100%",
            }}
          >
            <Col>
              <strong style={{ fontSize: 24, color: "#333" }}>Lojas</strong>
            </Col>

            <Col>
              <Input
                disabled={!lojas.length}
                placeholder="Pesquisar lojas..."
                value={searchText}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </Col>

            <Col>
              <Button
                type="primary"
                onClick={handleNavigateToAdicionarLoja}
                icon={<PlusOutlined />}
              >
                Adicionar loja
              </Button>
            </Col>
          </Row>
          <Col xs="24" xl={24}>
            {lojas.length === 0 && (
              <EmptyPlaceholder
                title="Você ainda não adicionou nenhuma loja..."
                text="Clique no botão abaixo para adicionar sua primeira loja"
                buttonText="Adicionar minha primeira loja"
                buttonClick={handleNavigateToAdicionarLoja}
              />
            )}

            {lojas.length > 0 && (
              <Card bordered={false} className="criclebox tablespace mb-24">
                <div className="table-responsive">
                  <Table
                    loading={isLoadingGetLojas}
                    columns={columns}
                    dataSource={searchText ? filteredLojas : lojas}
                    pagination={{
                      pageSize: 7,
                      position: ["bottomRight"],
                      showSizeChanger: false,
                    }}
                    className="ant-border-space"
                  />
                </div>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Lojas;

import React, { useState } from "react";
import {
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Tabs,
  Typography,
} from "antd";
import Button from "../../../components/Button";
import PropTypes from "prop-types";

const { Text, Title } = Typography;

export default function EstilosDoCheckout({
  newLojaInfo,
  setNewLojaInfo,
  handleCancelButtonClick,
  handleClickBackButton,
  handleNextButtonClick,
}) {
  const onSkip = async () => {
    setNewLojaInfo({
      ...newLojaInfo,
      main_color: "#36b376",
      header_bg_color: "#ffffff",
      show_countdown: "yes",
    });

    handleNextButtonClick();
  };

  const onFinish = async (values) => {
    setNewLojaInfo({
      ...newLojaInfo,
      main_color: values.main_color,
      header_bg_color: values.header_bg_color,
      show_countdown: values.show_countdown,
    });

    handleNextButtonClick();
  };

  return (
    <Form onFinish={onFinish}>
      <Row gutter={[24, 32]} style={{ margin: "62px auto 0" }}>
        <Col span={24} style={{ lineHeight: "1" }}>
          <Title level={5}>
            4. Personalizar estilos da página de checkout da sua loja.
          </Title>
          <Text style={{ color: "#666" }}>
            Personalize a aparência da página de checkout da sua loja, você pode
            escolher a <strong>cor principal</strong>,{" "}
            <strong>cor do cabeçalho</strong>, <strong>mostrar contador</strong>{" "}
            e em breve muito mais...
          </Text>
        </Col>

        <Col span={12}>
          <Text strong>Cor principal</Text>
          <Form.Item name="main_color">
            <Input type="color" size="large" defaultValue="#36b376" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Text strong>Cor do cabeçalho</Text>
          <Form.Item name="header_bg_color">
            <Input type="color" size="large" defaultValue="#ffffff" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Text strong>Mostrar contador?</Text>
          <Form.Item name="show_countdown">
            <Select
              placeholder="Selecione"
              size="large"
              defaultValue="yes"
              options={[
                { key: "yes", value: "yes", label: "Sim" },
                { key: "no", value: "no", label: "Não" },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              padding: 14,
            }}
          >
            <div>
              <Button danger type="link" onClick={handleCancelButtonClick}>
                Cancelar
              </Button>
            </div>

            <div style={{ display: "flex", alignItems: "center", gap: 24 }}>
              <Button type="text" onClick={handleClickBackButton}>
                Voltar
              </Button>

              <Button onClick={onSkip}>Pular e Finalizar cadastro</Button>

              <Button type="primary" htmlType="submit">
                Salvar e Finalizar cadastro
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

//====================> PROP TYPES <====================
EstilosDoCheckout.propTypes = {
  newLojaInfo: PropTypes.object.isRequired,
  setNewLojaInfo: PropTypes.func.isRequired,
  handleCancelButtonClick: PropTypes.func.isRequired,
  handleClickBackButton: PropTypes.func.isRequired,
  handleFinishButonClick: PropTypes.func.isRequired,
  handleNextButtonClick: PropTypes.func.isRequired,
};
//====================> PROP TYPES <====================

import React, { useEffect, useState } from "react";
import { Col, Input, Modal, Row, Select } from "antd";
import getProdutosList from "../../../services/produtos/getProdutosList.service";
import adicionarPixel from "../../../services/pixels/adicionarPixel.service";
import toast from "react-hot-toast";

export default function AdicionarPixel({ isOpen, handleClose }) {
  const [produtos, setProdutos] = useState([]);
  const [isLoadingGetProdutos, setIsLoadingGetProdutos] = useState(false);
  const [isLoadingCreatePixel, setIsLoadingCreatePixel] = useState(false);
  const [code, setCode] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [productId, setProductId] = useState("");

  useEffect(() => {
    getProducts();
  }, []);

  async function getProducts() {
    try {
      setIsLoadingGetProdutos(true);
      const { produtos } = await getProdutosList();
      setProdutos(produtos);
    } catch (error) {
    } finally {
      setIsLoadingGetProdutos(false);
    }
  }

  const handleChangeProduct = (option) => {
    setProductId(option);
  };

  const handleSavePixel = async () => {
    try {
      setIsLoadingCreatePixel(true);
      const response = await adicionarPixel({
        code: code,
        access_token: accessToken,
        product_id: productId,
      });

      if (response === "ok") {
        toast.success("Código pixel adicionado com sucesso!");
        handleCancel();
      }
    } catch (error) {
      toast.error("Não foi possível adicionar este código pixel!");
    } finally {
      setIsLoadingCreatePixel(false);
    }
  };

  const handleCancel = () => {
    setCode("");
    setProductId("");
    setAccessToken("");
    handleClose();
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={handleCancel}
      title="Adicionar pixel"
      cancelText="Cancelar"
      okText="Adicionar pixel"
      onOk={handleSavePixel}
      okButtonProps={{
        loading: isLoadingCreatePixel,
        style: { background: "#222", borderColor: "#222" },
      }}
    >
      <Row span={24} gutter={[24, 24]}>
        <Col span={24}>
          <label style={{ fontWeight: 500, marginBottom: 4 }}>Produto</label>
          <Select
            style={{ width: "100%" }}
            size="large"
            placeholder="Selecione o produto"
            loading={isLoadingGetProdutos}
            onChange={handleChangeProduct}
            options={produtos.map((produto) => ({
              key: produto.id,
              label: produto.label,
              value: produto.id,
            }))}
          />
        </Col>

        <Col span={24}>
          <label style={{ fontWeight: 500, marginBottom: 4 }}>
            Código Pixel
          </label>
          <Input
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Cole aqui o código pixel"
            size="large"
          />
        </Col>

        <Col span={24}>
          <label style={{ fontWeight: 500, marginBottom: 0 }}>
            Access Token <small style={{ color: "#1d1e1d" }}>(opcional)</small>
          </label>

          <Input
            value={accessToken}
            onChange={(e) => setAccessToken(e.target.value)}
            placeholder="Cole aqui o access token"
          />
          <small>
            Cole acima o Access Token da API de conversões do Facebook
          </small>
        </Col>
      </Row>
    </Modal>
  );
}

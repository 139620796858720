import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  Col,
  Input,
  Row,
  Tooltip,
  Typography,
  Radio,
  InputNumber,
  Select,
  Checkbox,
} from "antd";
import Button from "../../components/Button";
import { ArrowLeftOutlined } from "@ant-design/icons";
import getLojasList from "../../services/lojas/getLojasList.service";
import ShippingMethods from "./layout/ShippingMethods";
import createProduct from "../../services/produtos/createProduto.service";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import getProductById from "../../services/produtos/getProductById.service";
import editarProduto from "../../services/produtos/editarProduto.service";

const { Text } = Typography;

export default function EditarProduto() {
  const history = useHistory();
  const params = useParams();

  const [newProductType, setNewProductType] = useState("digital");
  const [newProdutoInfo, setNewProdutoInfo] = useState(null);
  const [isLoadingCreateProduto, setIsLoadingCreateProduto] = useState(false);
  const [shippingMethods, setShippingMethods] = useState([]);
  const [acceptPix, setAcceptPix] = useState(true);
  const [acceptCreditCard, setAcceptCreditCard] = useState(false);
  const [acceptBankSlip, setAcceptBankSlip] = useState(false);

  useEffect(() => {
    const productId = params.id;

    if (productId) {
      getProduct(productId);
    }
  }, [params.id]);

  async function getProduct(id) {
    const { product } = await getProductById(id);

    console.log({ product });

    setNewProdutoInfo({
      label: product?.label,
      price: product?.price,
      image: product?.image,
    });
    setNewProductType(product.isDigital ? "digital" : "fisico");
    setShippingMethods(product.shipping_methods);
    setAcceptPix(product?.accept_pix);
    setAcceptCreditCard(product?.accept_credit_card);
    setAcceptBankSlip(product?.accept_bank_slip);
  }

  const handleChangeProductType = (e) => {
    setNewProductType(e.target.value);
  };

  const handleChangeProductName = (e) => {
    setNewProdutoInfo({ ...newProdutoInfo, label: e.target.value });
  };

  const handleChangeProductPrice = (value) => {
    setNewProdutoInfo({ ...newProdutoInfo, price: value });
  };

  const handleChangeNewProductImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setNewProdutoInfo({ ...newProdutoInfo, image: reader.result });
      };
    } else {
      return "";
    }
  };

  const handleChangeAcceptPix = (e) => {
    setAcceptPix(e.target.checked);
  };

  const handleChangeAcceptCreditCard = (e) => {
    setAcceptCreditCard(e.target.checked);
  };

  const handleChangeAcceptBankSlip = (e) => {
    setAcceptBankSlip(e.target.checked);
  };

  const handleSaveProduct = async () => {
    try {
      setIsLoadingCreateProduto(true);

      const response = await editarProduto({
        id: params.id,
        label: newProdutoInfo?.label,
        price: Number(newProdutoInfo?.price),
        image: newProdutoInfo?.image,
        productType: newProductType,
        shipping_methods: shippingMethods,
        accept_pix: acceptPix,
        accept_credit_card: acceptCreditCard,
        accept_bank_slip: acceptBankSlip,
      });

      if (response === "ok") {
        toast.success(
          `As alterações no produto ${newProdutoInfo?.label} foram salvas.`,
          { duration: 8000 }
        );
        setNewProductType("");
        setNewProdutoInfo(null);
        setShippingMethods([]);
        history.push("/produtos");
      }
    } catch (error) {
      toast.error(
        "Houve um erro ao tentar atualizar as informações deste produto."
      );
    } finally {
      setIsLoadingCreateProduto(false);
    }
  };

  const handleCancelClick = () => {
    setNewProductType("");
    setNewProdutoInfo("");
    history.push("/produtos");
  };

  return (
    <>
      <div
        className="layout-content mt-25"
        style={{ maxWidth: 1200, margin: "0 auto", marginTop: 32 }}
      >
        <div
          style={{
            marginBottom: 24,
            display: "flex",
            alignItems: "center",
            gap: 24,
          }}
        >
          <Tooltip title="Voltar para produtos" placement="bottom">
            <small
              style={{
                cursor: "pointer",
                fontSize: 14,
                display: "flex",
                alignItems: "center",
                gap: 4,
              }}
              onClick={() => history.push("/produtos")}
            >
              <ArrowLeftOutlined />
              Produtos
            </small>
          </Tooltip>
          <strong style={{ fontSize: 24, color: "#333" }}>
            Editar produto
          </strong>
        </div>

        <Row gutter={[24, 24]} style={{ maxWidth: "1200px", margin: "0 auto" }}>
          <Col span={24}>
            <Card title="Informações básicas do produto">
              <Row gutter={[24, 24]}>
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <img
                    src={newProdutoInfo?.image}
                    alt="logo do produto"
                    height={120}
                  />
                  <Text strong>Imagem do produto</Text>
                  <Input
                    type="file"
                    accept="image/*"
                    onChange={handleChangeNewProductImage}
                  />
                </Col>

                <Col span={12}>
                  <Text strong>Nome</Text>
                  <br />
                  <Input
                    placeholder="Digite o nome do produto"
                    onChange={handleChangeProductName}
                    value={newProdutoInfo?.label}
                  />
                </Col>

                <Col span={12}>
                  <Text strong>Preço</Text>
                  <br />
                  <InputNumber
                    size="large"
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    onChange={handleChangeProductPrice}
                    value={newProdutoInfo?.price}
                  />
                </Col>

                <Col span={12}>
                  <Text strong>
                    Formas de pagamento aceitas para este produto:
                  </Text>

                  <div style={{ marginTop: 12 }}>
                    <Checkbox
                      checked={acceptCreditCard}
                      onChange={handleChangeAcceptCreditCard}
                    >
                      Cartão de crédito
                    </Checkbox>

                    <Checkbox
                      checked={acceptPix}
                      onChange={handleChangeAcceptPix}
                    >
                      Pix
                    </Checkbox>

                    {/* <Checkbox
                      disabled
                      checked={acceptBankSlip}
                      onChange={handleChangeAcceptBankSlip}
                    >
                      Boleto
                    </Checkbox> */}
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>

          {newProductType === "fisico" && (
            <ShippingMethods
              shippingMethods={shippingMethods}
              setShippingMethods={setShippingMethods}
            />
          )}

          <Col span={24}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                onClick={handleCancelClick}
                loading={isLoadingCreateProduto}
                type="ghost"
              >
                Descartar alterações
              </Button>
              <Button
                loading={isLoadingCreateProduto}
                type="primary"
                onClick={handleSaveProduct}
              >
                Salvar
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

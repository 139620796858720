import {
  CheckCircleOutlined,
  FileTextOutlined,
  MoreOutlined,
  RollbackOutlined,
  WhatsAppOutlined,
  ReloadOutlined,
  FilterFilled,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Input,
  Menu,
  Row,
  Table,
  Tag,
} from "antd";
import { useEffect, useState } from "react";
import getPedidos from "../../services/pedidos/getPedidos.service";
import changePaymentStatus from "../../services/pedidos/changePaymentStatus.service";
import toast from "react-hot-toast";
import { formatarDataBR } from "../../utils/formatTime";
import OrderDetails from "./layout/OrderDetails";
import EmptyPlaceholder from "../../components/EmptyPlaceholder";
import Filters from "./layout/Filters";

function Pedidos() {
  const [pedidos, setPedidos] = useState([]);
  const [isLoadingGetPedidos, setIsLoadingGetPedidos] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [orderBy, setOrderBy] = useState("desc");
  const [pagination, setPagination] = useState({
    page_index: 1,
    page_size: 25,
    totalCount: 10,
  });

  const [filteredPedidos, setFilteredPedidos] = useState([]);
  const [isOpenOrderDetails, setIsOpenOrderDetails] = useState({
    isOpen: false,
    order: null,
  });

  useEffect(() => {
    handleGetPedidos({ page_index: pagination.page_index });
  }, [pagination.page_index]);

  const handleGetPedidos = async ({ page_index }) => {
    try {
      setIsLoadingGetPedidos(true);
      const { pedidos, totalCount } = await getPedidos({
        page_index: page_index - 1, // pq a paginação no DB começa na pagina 0.
        page_size: 25,
      });

      if (pedidos) {
        setPedidos(pedidos);
        setPagination({ page_index, totalCount });
      }
    } catch (error) {
    } finally {
      setIsLoadingGetPedidos(false);
    }
  };

  function handleSearch(text) {
    setSearchText(text);
    const filtered = pedidos.filter((produto) =>
      produto.payment_identifier.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredPedidos(filtered);
  }

  const handleApprovePayment = async (order) => {
    try {
      setIsLoadingGetPedidos(true);
      const response = await changePaymentStatus({
        payment_identifier: order.payment_identifier,
        payment_status: "confirmed",
      });

      if (response === "ok") {
        toast.success(
          `PEDIDO: #${order.payment_identifier}: PAGAMENTO APROVADO!`,
          {
            duration: 7000,
            position: "top-center",
          }
        );

        // Atualize o estado 'pedidos'
        setPedidos((prevPedidos) => {
          // Crie uma cópia do estado
          const updatedPedidos = [...prevPedidos];

          // Encontre o índice do pedido com o mesmo ID no array de pedidos
          const index = updatedPedidos.findIndex(
            (item) => item.id === order.id
          );

          if (index !== -1) {
            // Atualize a propriedade 'payment_status' do pedido
            updatedPedidos[index].payment_status = "confirmed";
          }

          return updatedPedidos; // Retorna a nova cópia do estado
        });
      }
    } catch (error) {
      // Lide com erros, se necessário
      console.log(error);
    } finally {
      setIsLoadingGetPedidos(false);
    }
  };

  const handleRemovePaymentApprove = async (order) => {
    try {
      setIsLoadingGetPedidos(true);
      const response = await changePaymentStatus({
        payment_identifier: order.payment_identifier,
        payment_status: "pending",
      });

      if (response === "ok") {
        toast.success(
          `PEDIDO: #${order.payment_identifier}: CONFIRMAÇÃO DE PAGAMENTO REMOVIDA!`,
          {
            duration: 7000,
            position: "top-center",
          }
        );

        // Atualize o estado 'pedidos'
        setPedidos((prevPedidos) => {
          // Crie uma cópia do estado
          const updatedPedidos = [...prevPedidos];

          // Encontre o índice do pedido com o mesmo ID no array de pedidos
          const index = updatedPedidos.findIndex(
            (item) => item.id === order.id
          );

          if (index !== -1) {
            // Atualize a propriedade 'payment_status' do pedido
            updatedPedidos[index].payment_status = "pending";
          }

          return updatedPedidos; // Retorna a nova cópia do estado
        });
      }
    } catch (error) {
      // Lide com erros, se necessário
    } finally {
      setIsLoadingGetPedidos(false);
    }
  };

  const handleRecuperarVenda = (order) => {
    window.open(
      `https://api.whatsapp.com/send?phone=+55${order?.buyer_phone}&text=Olá, ${order?.buyer_full_name}! Sou sua assistente virtual da ${order?.store?.label}, e estou aqui para te lembrar de pagar o pix do seu pedido. Queremos garantir qe você possa aproveitar seu ${order?.product?.label}, e para isso, vou explicar como pagar de forma rápida e fácil. 1. Abra o app do seu banco e selecione a opção Pix. 2. Vá na opção 'Pix Copia e Cola' e copie o código abaixo: *${order?.copy_and_past_pix}* 3. Cole o código de pagamento. Pronto! Agora é só esperar a confirmação de pagamento e receber o seu ${order?.product?.label}`,
      "_blank"
    );
  };

  const handleOpenOrderDetails = (order) => {
    setIsOpenOrderDetails({ isOpen: true, order: order });
  };

  const handleCloseOrderDetails = () => {
    setIsOpenOrderDetails({
      isOpen: false,
      order: null,
    });
  };

  const handleChangePagination = (page) => {
    setPagination({ ...pagination, page_index: page });
  };

  const columns = [
    {
      title: "Cliente",
      align: "left",
      key: "buyer_full_name",
      dataIndex: "buyer_full_name",
    },
    {
      title: "Preço",
      align: "center",
      key: "total_price",
      render: (data) => {
        return (
          <Tag className="tag-primary">
            {parseFloat(data.total_price).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </Tag>
        );
      },
    },
    {
      title: "Produto",
      dataIndex: "product",
      align: "center",
      key: "product",
      render: (data) => {
        return data.label;
      },
    },
    {
      title: "Criado em",
      dataIndex: "created_at",
      align: "center",
      key: "created_at",
      render: (data) => {
        return formatarDataBR(data);
      },
    },
    {
      title: "Status",
      dataIndex: "payment_status",
      key: "status",
      align: "center",
      render: (data) => {
        if (data === "pending") {
          return (
            <Tag className="tag-primary" color="warning">
              Aguardando pagamento
            </Tag>
          );
        }

        if (data === "confirmed") {
          return (
            <Tag className="tag-primary" color="success">
              Pagamento confirmado
            </Tag>
          );
        }

        if (data === "canceled") {
          return (
            <Tag className="tag-primary" color="error">
              Pedido cancelado
            </Tag>
          );
        }
      },
    },
    {
      title: "UTM Source",
      key: "utm_source",
      dataIndex: "utm_source",
    },
    {
      title: "UTM Medium",
      key: "utm_medium",
      dataIndex: "utm_medium",
    },
    {
      title: "UTM Campaign",
      key: "utm_campaign",
      dataIndex: "utm_campaign",
    },
    {
      title: "UTM Content",
      key: "utm_content",
      dataIndex: "utm_content",
    },
    {
      title: "UTM ID",
      key: "utm_id",
      dataIndex: "utm_id",
    },
    {
      title: "UTM Term",
      key: "utm_term",
      dataIndex: "utm_term",
    },
    {
      title: "AÇÕES",
      key: "actions",
      render: (data) => {
        return (
          <Dropdown
            trigger="click"
            overlay={
              <Menu>
                {data?.payment_status === "pending" && (
                  <Menu.Item
                    onClick={() => handleApprovePayment(data)}
                    icon={<CheckCircleOutlined style={{ fontSize: 18 }} />}
                  >
                    Aprovar pagamento
                  </Menu.Item>
                )}

                {data?.payment_status === "confirmed" && (
                  <Menu.Item
                    onClick={() => handleRemovePaymentApprove(data)}
                    icon={<RollbackOutlined style={{ fontSize: 18 }} />}
                  >
                    Remover confirmação de pagamento
                  </Menu.Item>
                )}

                {data?.payment_status === "pending" && (
                  <Menu.Item
                    onClick={() => handleRecuperarVenda(data)}
                    icon={<WhatsAppOutlined style={{ fontSize: 18 }} />}
                  >
                    Recuperar venda
                  </Menu.Item>
                )}

                <Menu.Item
                  onClick={() => handleOpenOrderDetails(data)}
                  icon={<FileTextOutlined style={{ fontSize: 18 }} />}
                >
                  Ver pedido
                </Menu.Item>
              </Menu>
            }
          >
            <Button icon={<MoreOutlined />} style={{ padding: "0 8px" }} />
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <OrderDetails
        isOpen={isOpenOrderDetails.isOpen}
        order={isOpenOrderDetails.order}
        handleClose={handleCloseOrderDetails}
      />

      <Filters isOpen={false} handleClose={() => {}} />

      <div
        className="layout-content mt-25"
        style={{ maxWidth: 1500, margin: "0 auto" }}
      >
        <Row gutter={[24, 0]}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "12px",
              marginBottom: "24px",
              width: "100%",
            }}
          >
            <strong style={{ fontSize: 24, color: "#333" }}>Pedidos</strong>

            {/* <Col>
              <Input
                placeholder="Pesquisar pedidos..."
                value={searchText}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </Col> */}

            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Button icon={<ReloadOutlined />}>Atualizar</Button>

              <Button type="default" icon={<FilterFilled />}>
                Filtrar
              </Button>
            </div>
          </div>
          <Col xs="24" xl={24}>
            <Card bordered={false} className="criclebox tablespace mb-24">
              <div className="table-responsive">
                <Table
                  locale={{
                    emptyText: (
                      <EmptyPlaceholder title="Você ainda não recebeu nenhum pedido" />
                    ),
                  }}
                  rowKey="id"
                  loading={isLoadingGetPedidos}
                  columns={columns}
                  dataSource={searchText ? filteredPedidos : pedidos}
                  pagination={{
                    showSizeChanger: false,
                    pageSize: 25,
                    total: pagination.totalCount,
                    onChange: handleChangePagination,
                  }}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Pedidos;

import { Switch, Route } from "react-router-dom";
import Main from "./components/layout/Main";
import Dashboard from "./pages/dashboard";
import SignUp from "./pages/signUp";
import SignIn from "./pages/signIn";
import Pedidos from "./pages/pedidos";
import Lojas from "./pages/lojas";
import AdicionarLoja from "./pages/adicionarLoja";
import Produtos from "./pages/produtos";
import Pixel from "./pages/pixel";
import AdicionarProduto from "./pages/adicionarProduto";
import { AuthProvider } from "./contexts/AuthContext";
import { Toaster } from "react-hot-toast";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import EditarProduto from "./pages/editarProduto";

function App() {
  return (
    <AuthProvider>
      <div className="App">
        <Toaster />
        <Switch>
          <Route path="/" exact component={SignIn} />
          <Route path="/sign-up" exact component={SignUp} />
          <Main>
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/pedidos" component={Pedidos} />
            <Route exact path="/lojas" component={Lojas} />
            <Route exact path="/adicionar-loja" component={AdicionarLoja} />
            <Route exact path="/produtos" component={Produtos} />
            <Route
              exact
              path="/adicionar-produto"
              component={AdicionarProduto}
            />
            <Route exact path="/editar-produto/:id" component={EditarProduto} />
            <Route exact path="/pixel" component={Pixel} />
          </Main>
        </Switch>
      </div>
    </AuthProvider>
  );
}

export default App;

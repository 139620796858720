import React from "react";
import UndrawSad from "../../assets/images/undraw_feeling_blue_-4-b7q.svg";
import Button from "../Button";

export default function EmptyPlaceholder({
  buttonClick,
  buttonText,
  title,
  text,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 48,
        textAlign: "center",
        margin: "0 auto",
      }}
    >
      <img src={UndrawSad} height={142} alt="" />
      <h2>{title}</h2>
      <span className="mb-25" style={{ fontSize: 18, maxWidth: 465 }}>
        {text}
      </span>
      <Button size="large" type="primary" onClick={buttonClick}>
        {buttonText}
      </Button>
    </div>
  );
}

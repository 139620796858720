import React from "react";
import { Layout, Typography, Card, Form, Input, Checkbox } from "antd";
import Button from "../../components/Button";
import logo from "../../assets/images/black-pay-logo.jpg";

import { Link } from "react-router-dom";
import criarUser from "../../services/users/criarUser.service";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

const { Title } = Typography;
const { Header, Footer, Content } = Layout;

export default function SignUp() {
  const history = useHistory();
  const onFinish = async (values) => {
    try {
      const response = await criarUser(values);
      if (response === "ok") {
        toast.success(
          "Parabéns, sua conta foi criada com sucesso! Agora você pode fazer Login",
          {
            duration: 7000,
          }
        );
        history.push("/");
      }
    } catch (error) {
      toast.error(error.message, { duration: 7000 });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <div className="layout-default ant-layout layout-sign-up">
        <Header>
          <div className="header-col header-brand">
            <img src={logo} alt="BlackPay Logo" />
            <h5>BlackPay</h5>
          </div>
        </Header>

        <Content className="p-0">
          <div className="sign-up-header">
            <div className="content">
              <Title>Criar conta</Title>
              <p className="text-lg">
                Preencha os dados abaixo e crie uma conta em poucos segundos.
              </p>
            </div>
          </div>

          <Card
            className="card-signup header-solid h-full ant-card pt-0"
            title={<h5>Inscreva-se com</h5>}
            bordered="false"
          >
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="row-col"
            >
              <Form.Item
                name="name"
                rules={[
                  { required: true, message: "Por favor, digite seu nome" },
                ]}
              >
                <Input placeholder="Nome" />
              </Form.Item>

              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Por favor, digite seu e-mail" },
                ]}
              >
                <Input placeholder="E-mail" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Por favor, digite sua senha" },
                  {
                    min: 6,
                    message: "Sua senha precisa ter no mínimo 6 caracteres",
                  },
                ]}
              >
                <Input placeholder="Senha" type="password" />
              </Form.Item>

              <Form.Item name="remember" valuePropName="checked">
                <Checkbox>
                  Eu aceito os{" "}
                  <a href="#pablo" className="font-bold text-dark">
                    Termos e Condições
                  </a>
                </Checkbox>
              </Form.Item>

              <Form.Item>
                <Button fullWidth type="primary" htmlType="submit">
                  SIGN UP
                </Button>
              </Form.Item>
            </Form>
            <p className="font-semibold text-muted text-center">
              Já tem uma conta?{" "}
              <Link to="/" className="font-bold text-dark">
                Entrar
              </Link>
            </p>
          </Card>
        </Content>
        <Footer>
          <p className="copyright">
            Copyright © 2023 by <strong>#SouBlackPay</strong>.
          </p>
        </Footer>
      </div>
    </>
  );
}

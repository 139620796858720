import api from "../../api/axios";

export default async function trocarChavePix(data) {
  try {
    const { store_id, pix_key_type, pix_key } = data;

    const response = await api.patch("stores/pix-key", {
      store_id,
      pix_key_type,
      pix_key,
    });

    if (response.status === 200) {
      return "ok";
    }
  } catch (error) {}
}

import React from "react";
import { Col, Drawer, Input, Row } from "antd";
import PropTypes from "prop-types";
import BlackPayLogo from "../../../assets/images/black-pay-logo.jpg";

export default function Filters({ isOpen, handleClose }) {
  return (
    <Drawer
      title={
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <img
            src={BlackPayLogo}
            alt="black pay"
            style={{ height: 42, borderRadius: 12 }}
          />

          <strong style={{ fontSize: 24 }}>Filtrar pedidos</strong>
        </div>
      }
      visible={isOpen}
      onClose={handleClose}
      placement="right"
      width={500}
    >
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Input placeholder="UTM_SOURCE" />
        </Col>
      </Row>
    </Drawer>
  );
}

Filters.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};

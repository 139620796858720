import api from "../../api/axios";

export default async function criarUser(data) {
  try {
    const { name, email, password } = data;

    const { status } = await api.post("users", {
      name,
      email,
      password,
      phone: null,
    });

    if (status === 201) {
      return "ok";
    }
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}

import { Modal } from "antd";
import React from "react";

export default function DeleteStoreConfirmation({
  isOpen,
  handleClose,
  store,
  confirmDelete,
  isLoadingDeleteStore,
}) {
  return (
    <Modal
      visible={isOpen}
      title="Apagar loja"
      okType="danger"
      okText="Quero apagar esta loja"
      cancelText="Cancelar"
      onCancel={handleClose}
      onOk={confirmDelete}
      okButtonProps={{
        loading: isLoadingDeleteStore,
      }}
    >
      <p>
        <strong>
          Você tem certeza que deseja apagar a loja {store?.label} ?
        </strong>
      </p>

      <strong style={{ color: "#ff4d4f" }}>
        Atenção: Esta ação também irá apagar todos os produtos, pedidos e
        acessos relacionados à esta loja
      </strong>
    </Modal>
  );
}

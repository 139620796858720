import api from "../../api/axios";

export default async function apagarLoja(id) {
  try {
    const response = await api.delete(`stores/${id}`);
    console.log(response);

    if (response.status === 200) {
      return "ok";
    }
  } catch (error) {}
}

import React, { useEffect, useState } from "react";
import { Col, Row, Typography, Skeleton } from "antd";
import Button from "../../../components/Button";
import { ArrowLeftOutlined, LoadingOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import criarLoja from "../../../services/lojas/criarLoja.service";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import CongratulationSVG from "../../../assets/images/undraw-winners.svg";

const { Title, Text } = Typography;

export default function Resumo({
  newLojaInfo,
  setNewLojaInfo,
  handleCancelButtonClick,
  handleNextButtonClick,
  isLoadingCreateLoja,
  setIsLoadingCreateLoja,
}) {
  const history = useHistory();
  const [lojaCriada, setLojaCriada] = useState(null);

  useEffect(() => {
    const createLoja = async () => {
      try {
        setIsLoadingCreateLoja(true);
        const { store } = await criarLoja(newLojaInfo);
        setLojaCriada(store);
      } catch (error) {
        toast.error(error.response.data.message, { duration: 7000 });
      } finally {
        setTimeout(() => setIsLoadingCreateLoja(false), 1200);
      }
    };

    createLoja();
  }, []);

  return (
    <>
      <Row gutter={[24, 12]} style={{ margin: "24px auto 0" }}>
        {isLoadingCreateLoja ? (
          <Row gutter={[24, 32]}>
            <Col span={24} style={{ lineHeight: "1" }}>
              <Title level={5}>
                Adicionando sua nova loja no nosso banco de dados
              </Title>
              <Text style={{ color: "#666" }}>
                <LoadingOutlined /> Aguarde enquanto a sua nova loja é
                adicionada...
              </Text>
            </Col>

            <Col span={24} style={{ lineHeight: "1" }}>
              <Skeleton
                active
                round
                loading
                title={false}
                avatar={{
                  size: "large",
                  style: {
                    width: 182,
                    height: 182,
                  },
                }}
                paragraph={{
                  rows: 3,
                  width: "100%",
                }}
                className="skeleton-congratulation-create-loja"
              />
            </Col>
          </Row>
        ) : (
          <>
            <Col span={24} style={{ lineHeight: "1" }}></Col>

            <Col span={24}>
              <div className="created-loja-info-wrapper">
                <img
                  src={CongratulationSVG}
                  alt="congratulations"
                  className="congratulations-svg"
                />

                <Title level={5}>
                  Parabéns! Sua loja foi adicionada com sucesso!
                </Title>
                <Text style={{ color: "#666" }}>
                  Você pode criar outra loja ou voltar para a lista de lojas
                  clicando nos botões abaixo
                </Text>
              </div>
            </Col>
          </>
        )}

        {!isLoadingCreateLoja && (
          <Col
            span={24}
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: 24,
            }}
          >
            <Button
              icon={<ArrowLeftOutlined />}
              type="link"
              onClick={() => history.push("/lojas")}
            >
              Voltar para lista de lojas
            </Button>
            <Button type="ghost" onClick={() => window.location.reload()}>
              Adicionar outra loja
            </Button>
          </Col>
        )}
      </Row>
    </>
  );
}

//====================> PROP TYPES <====================

Resumo.propTypes = {
  newLojaInfo: PropTypes.object.isRequired,
};

//====================> PROP TYPES <====================

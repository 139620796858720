import React, { useState } from "react";
import { Col, Input, Row, Tooltip, Typography, Form } from "antd";
import Button from "../../../components/Button";
import { InfoCircleOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

const { Title, Text } = Typography;

export default function InformacoesGerais({
  newLojaInfo,
  setNewLojaInfo,
  handleCancelButtonClick,
  handleNextButtonClick,
}) {
  const [b64Logo, setB64Logo] = useState("");

  const handleChangeNewLojaLogo = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setB64Logo(reader.result);
      };
    } else {
      return "";
    }
  };

  const onFinish = (values) => {
    const formattedSiteURL = values.site_url.replace(/\/+$/g, "");

    setNewLojaInfo({
      ...newLojaInfo,
      label: values.label,
      logo_image: b64Logo,
      site_url: formattedSiteURL,
    });

    handleNextButtonClick();
  };

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };

  return (
    <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <Row gutter={[24, 32]} style={{ margin: "24px auto 0" }}>
        <Col span={24} style={{ lineHeight: "1" }}>
          <Title level={5}>1. Informações básicas da loja</Title>
          <Text style={{ color: "#666" }}>
            Preencha o nome, imagem da logo e domínio da nova loja a ser
            adicionada.
          </Text>
        </Col>

        <Col span={12} xs={24} sm={24}>
          <Text strong>Nome da loja</Text>
          <Form.Item
            name="label"
            rules={[
              { required: true, message: "O nome da loja é obrigatório" },
            ]}
          >
            <Input placeholder="Ex.: Loja Exemplo" />
          </Form.Item>
        </Col>

        <Col span={12} xs={24} sm={24}>
          <Text strong>Imagem da logo</Text>

          <Form.Item
            name="logo_image"
            rules={[
              { required: true, message: "A logo da loja é obrigatória" },
            ]}
          >
            <Input
              type="file"
              accept="image/*"
              onChange={handleChangeNewLojaLogo}
            />
          </Form.Item>
        </Col>

        <Col span={12} xs={24} sm={24}>
          <Tooltip placement="bottom" title={titleTooltipSiteURL}>
            <Text strong>Domínio</Text>
            &nbsp;
            <InfoCircleOutlined style={{ fontSize: 12 }} />
          </Tooltip>

          <Form.Item
            name="site_url"
            rules={[
              {
                required: true,
                message: "Por favor, digite o domínio da loja",
              },
              {
                type: "url",
                message:
                  "Por favor, insira uma URL válida: https://exemplodomain.com",
              },
              {
                transform: (value) =>
                  value ? value.replace(/\/$/, "") : value,
              },
            ]}
          >
            <Input placeholder="https://exemplodominio.com.br" />
          </Form.Item>
        </Col>

        <Col
          span={24}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button type="text" danger onClick={handleCancelButtonClick}>
            Cancelar
          </Button>
          <Button htmlType="submit" type="primary">
            Salvar e Continuar
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

//====================> PROP TYPES <====================

InformacoesGerais.propTypes = {
  newLojaInfo: PropTypes.object.isRequired,
  handleChangeNewLoja: PropTypes.func.isRequired,
};

//====================> PROP TYPES <====================

const titleTooltipSiteURL = [
  <Row gutter={[24, 24]}>
    <Col span={24}>
      Domínio da sua nova loja a ser adicionada, por exemplo:
      https://compracerta.com ou https://compracerta.online
    </Col>
    <Col span={24}>
      <strong>OBS: </strong>
      O checkout da BlackPay ficará hospedado no seu domínio em uma subpasta
      chamada: "/checkout" através de um arquivo de integração.
      <br />
      <br />
      <strong>Dica rápida: </strong>
      Os links de pagamento dos seus produtos serão parecidos com este:
      <br />
      <strong
        style={{
          fontSize: "13px",
        }}
      >
        https://compracerta.com/checkout/index.html?p=49841525
      </strong>
    </Col>
  </Row>,
];

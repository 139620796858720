export function formatarDataBR(data) {
  const dataObj = new Date(data);
  const optionsData = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  const optionsHora = {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  const formatadorData = new Intl.DateTimeFormat("pt-BR", optionsData);
  const formatadorHora = new Intl.DateTimeFormat("pt-BR", optionsHora);

  const dataFormatada = formatadorData.format(dataObj);
  const horaFormatada = formatadorHora.format(dataObj);

  return `${dataFormatada} às ${horaFormatada}`;
}

export function isVisitOnline(dateString) {
  const currentDate = new Date();
  const createdAtDate = new Date(dateString);
  const timeDifference = currentDate - createdAtDate;
  return timeDifference >= 0 && timeDifference <= 2 * 60 * 1000;
}

import { useEffect, useState } from "react";
import { Card, Col, Row, Skeleton, Table, Typography } from "antd";
import {
  FundViewOutlined,
  ShoppingCartOutlined,
  DollarCircleOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import api from "../../api/axios";
import EmptyPlaceholder from "../../components/EmptyPlaceholder";
import Button from "../../components/Button";

const { Title, Paragraph } = Typography;

export default function Dashboard() {
  const { Title } = Typography;
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState({
    orders: 0,
    products: 0,
    stores: 0,
    visits: 0,
    total_billed: 0,
    generated_orders_today: 0,
    online_people_now: [],
  });

  useEffect(() => {
    const getDashboardData = async () => {
      try {
        setIsLoading(true);
        const response = await api.get("dashboard");
        const data = response.data;

        setDashboardData({
          orders: data.orders,
          products: data.products,
          stores: data.stores,
          visits: data.visits,
          total_billed: data.total_billed,
          generated_orders_today: data.generated_orders_today,
          online_people_now: data.online_people_now,
        });
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    getDashboardData();
  }, []);

  function handleNotification() {
    if ("Notification" in window) {
      if (Notification.permission === "granted") {
        document.getElementById("notify-audio").play();
        new Notification("Venda realizada!", {
          icon: "https://app.soublackpay.com/static/media/black-pay-logo.9a08be18.jpg",
          body: `Valor: R$ 97,00`,
          tag: Math.random(),
          silent: true,
          timestamp: 3000,
        });
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(function (permission) {
          if (permission === "granted") {
            // Permissão concedida, crie a notificação.
            new Notification("Venda realizada!", {
              icon: "https://app.soublackpay.com/static/media/black-pay-logo.9a08be18.jpg",
              body: `Valor: R$ ${Math.random().toFixed(2)}`,
              tag: Math.random(),
            });
          }
        });
      }
    }
  }

  const count = [
    {
      today: "Total R$",
      title: parseFloat(dashboardData.total_billed).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      }),
      // persent: "+30%",
      icon: <DollarCircleOutlined />,
      bnb: "bnb2",
    },
    {
      today: "Pedidos gerados",
      title: `${dashboardData.orders}`,
      // persent: "-10%",
      icon: <ShoppingCartOutlined />,
      bnb: "redtext",
    },
    {
      today: "Total de acessos",
      title: `${dashboardData.visits}`,
      // persent: "+20%",
      icon: <FundViewOutlined />,
      bnb: "bnb2",
    },
    {
      today: "Pessoas online agora",
      title: dashboardData.online_people_now.length,
      // persent: "-20%",
      icon: <TeamOutlined />,
      bnb: "redtext",
    },
  ];

  return (
    <>
      <div className="layout-content">
        <Row className="rowgap-vbox mt-25" gutter={[24, 0]}>
          {count.map((c, index) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={12}
              lg={6}
              xl={6}
              className="mb-24"
            >
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>{c.today}</span>
                      <Title level={4}>
                        {isLoading ? (
                          <Skeleton active paragraph={0} size="large" />
                        ) : (
                          <>
                            {" "}
                            {c.title}{" "}
                            <small className={c.bnb}>{c.persent}</small>
                          </>
                        )}
                      </Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">{c.icon}</div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
        </Row>

        {/* <Row>
          <Col>
            <Button onClick={handleNotification}>Notificate</Button>
          </Col>
        </Row> */}

        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <div className="linechart">
                <Title level={5}>Pessoas online agora</Title>
              </div>
              <Table
                loading={isLoading}
                locale={{
                  emptyText: (
                    <EmptyPlaceholder title="Nenhuma pessoa online nas suas lojas no momento" />
                  ),
                }}
                dataSource={dashboardData.online_people_now}
                columns={[
                  {
                    key: "01",
                    title: "IP Público",
                    dataIndex: "public_ip",
                  },
                  {
                    key: "02",
                    title: "Online em",
                    dataIndex: "online_in",
                  },
                  {
                    key: "04",
                    title: "Status",
                    render: () => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 4,
                        }}
                      >
                        <div
                          style={{
                            height: 12,
                            width: 12,
                            borderRadius: "50%",
                            background: "#1d1e",
                          }}
                        />
                        <span>Online</span>
                      </div>
                    ),
                  },
                  // {
                  //   key: "02",
                  //   title: "Dispositivo",
                  //   dataIndex: "device",
                  // },
                  // {
                  //   key: "03",
                  //   title: "Localização aprox.",
                  //   dataIndex: "location",
                  // },
                ]}
              />
            </Card>
          </Col>
        </Row>

        {/* <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <Echart />
            </Card>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <LineChart />
            </Card>
          </Col>
        </Row> */}
      </div>
    </>
  );
}

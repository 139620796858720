import api from "../../api/axios";

export default async function editarProduto(data) {
  try {
    const { id } = data;

    console.log(data);
    const response = await api.patch(`products/edit/${id}`, data);

    if (response.status === 200) {
      return "ok";
    }
  } catch (error) {
    console.log(error);
  }
}

import api from "../../api/axios";
export default async function criarLoja(data) {
  try {
    const {
      label,
      logo_image,
      site_url,
      pix_key_type,
      pix_key,
      host_smtp,
      port_smtp,
      email_smtp,
      password_smtp,
      main_color,
      header_bg_color,
      show_countdown,
    } = data;

    const response = await api.post("stores", {
      label,
      logo_image,
      site_url,
      pix_key_type,
      pix_key,
      host_smtp,
      port_smtp,
      email_smtp,
      password_smtp,
      checkout_configs: {
        main_color: main_color || "#36b376",
        header_bg_color: header_bg_color || "#ffffff",
        show_countdown: show_countdown === "yes" ? true : false,
      },
    });

    if (response.status === 201) {
      const store = response.data.store;
      return { store };
    }
  } catch (error) {}
}

import api from "../../api/axios";

export default async function apagarPixel(id) {
  try {
    const response = await api.delete(`pixel-code/${id}`);

    if (response.status === 200) {
      return "ok";
    }
  } catch (error) {}
}

import api from "../../api/axios";

export default async function updateCheckoutConfigs(data) {
  try {
    const { store_id, main_color, header_bg_color, show_countdown } = data;

    const response = await api.patch("stores/checkout-configs", {
      store_id,
      checkout_configs: {
        main_color,
        header_bg_color,
        show_countdown,
      },
    });

    if (response.status === 200) {
      return "ok";
    }
  } catch (error) {}
}

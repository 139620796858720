import React, { useState } from "react";
import { Col, Form, Input, Row, Select, Tabs, Typography } from "antd";
import Button from "../../../components/Button";
import PropTypes from "prop-types";
import MaskedInput from "antd-mask-input";

const { Text, Title } = Typography;

export default function FormasDePagamento({
  newLojaInfo,
  setNewLojaInfo,
  handleCancelButtonClick,
  handleClickBackButton,
  handleNextButtonClick,
}) {
  const [pixKeyType, setPixKeyType] = useState("");
  const [activeTab, setActiveTab] = useState("1");

  const handleChangePixKeyType = (option) => {
    setPixKeyType(option);
  };

  const handleChangeTabs = (key) => {
    setActiveTab(key);
  };

  const onFinish = (values) => {
    setNewLojaInfo({
      ...newLojaInfo,
      pix_key: values.pix_key,
      pix_key_type: values.pix_key_type,
    });

    handleNextButtonClick();
  };

  return (
    <Row gutter={[24, 32]} style={{ margin: "62px auto 0" }}>
      <Col span={24} style={{ lineHeight: "1" }}>
        <Title level={5}>2. Formas de pagamento da loja</Title>
        <Text style={{ color: "#666" }}>
          Configure as formas de pagamento que sua loja vai aceitar.
        </Text>
      </Col>

      <Col span={24}>
        <Tabs
          defaultActiveKey={activeTab}
          onChange={handleChangeTabs}
          type="card"
        >
          <Tabs.TabPane
            key="1"
            tab="PIX"
            children={
              <Form onFinish={onFinish}>
                <Row gutter={[24, 0]}>
                  <Col span={24}>
                    <Text strong>Tipo da Chave Pix</Text>
                    <Form.Item
                      name="pix_key_type"
                      rules={[
                        {
                          required: true,
                          message:
                            "O tipo da chave pix é obrigatório para adicionar a loja",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        onChange={handleChangePixKeyType}
                        placeholder="Selecione o tipo da chave pix"
                        options={[
                          {
                            value: "random",
                            label: "Aleatória",
                          },
                          {
                            value: "cpf",
                            label: "CPF",
                          },
                          {
                            value: "cnpj",
                            label: "CNPJ",
                          },
                          {
                            value: "phone",
                            label: "Telefone",
                          },
                          {
                            value: "email",
                            label: "E-mail",
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    {pixKeyType === "random" && (
                      <>
                        <Text strong>Chave Pix Aleatória</Text>
                        <Form.Item
                          name="pix_key"
                          rules={[
                            {
                              required: true,
                              message:
                                "A chave pix é obrigatória para adicionar a loja",
                            },
                          ]}
                        >
                          <Input placeholder="Ex.: 123e4567-e89b-12d3-a456-426655440000" />
                        </Form.Item>
                      </>
                    )}

                    {pixKeyType === "cpf" && (
                      <>
                        <Text strong>Chave Pix CPF</Text>
                        <Form.Item
                          name="pix_key"
                          rules={[
                            {
                              required: true,
                              message:
                                "A chave pix é obrigatória para adicionar a loja",
                            },
                          ]}
                        >
                          <MaskedInput
                            placeholder="Ex.: 123.456.789-10"
                            mask="111.111.111-11"
                          />
                        </Form.Item>
                      </>
                    )}

                    {pixKeyType === "cnpj" && (
                      <>
                        <Text strong>Chave Pix CNPJ</Text>
                        <Form.Item
                          name="pix_key"
                          rules={[
                            {
                              required: true,
                              message:
                                "A chave pix é obrigatória para adicionar a loja",
                            },
                          ]}
                        >
                          <MaskedInput
                            placeholder="Ex.: 12.345.678/0001-99"
                            mask="11.111.111/1111-11"
                          />
                        </Form.Item>
                      </>
                    )}

                    {pixKeyType === "phone" && (
                      <>
                        <Text strong>Chave Pix Telefone</Text>
                        <Form.Item
                          name="pix_key"
                          rules={[
                            {
                              required: true,
                              message:
                                "A chave pix é obrigatória para adicionar a loja",
                            },
                          ]}
                        >
                          <MaskedInput
                            placeholder="Ex.: (99) 9999-9999"
                            mask="(11) 11111-1111"
                          />
                        </Form.Item>
                      </>
                    )}

                    {pixKeyType === "email" && (
                      <>
                        <Text strong>Chave Pix E-mail</Text>
                        <Form.Item
                          name="pix_key"
                          rules={[
                            {
                              required: true,
                              message:
                                "A chave pix é obrigatória para adicionar a loja",
                            },
                            {
                              type: "email",
                              message:
                                "A chave pix do tipo e-mail precisa ser um e-mail válido.",
                            },
                          ]}
                        >
                          <Input placeholder="Ex.: fulano@gmail.com" />
                        </Form.Item>
                      </>
                    )}
                  </Col>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: 14,
                    }}
                  >
                    <div>
                      <Button
                        danger
                        type="link"
                        onClick={handleCancelButtonClick}
                      >
                        Cancelar
                      </Button>
                    </div>

                    <div style={{ display: "flex", gap: 24 }}>
                      <Button type="text" onClick={handleClickBackButton}>
                        Voltar
                      </Button>
                      <Button type="primary" htmlType="submit">
                        Salvar e Continuar
                      </Button>
                    </div>
                  </div>
                </Row>
              </Form>
            }
          />

          <Tabs.TabPane
            key="2"
            tab="CARTÃO DE CRÉDITO"
            children="Em breve..."
          />

          <Tabs.TabPane key="3" tab="BOLETO" children="Em breve..." />
        </Tabs>
      </Col>
    </Row>
  );
}

//====================> PROP TYPES <====================
FormasDePagamento.propTypes = {
  newLojaInfo: PropTypes.object.isRequired,
  setNewLojaInfo: PropTypes.func.isRequired,
  handleCancelButtonClick: PropTypes.func.isRequired,
  handleClickBackButton: PropTypes.func.isRequired,
  handleNextButtonClick: PropTypes.func.isRequired,
};
//====================> PROP TYPES <====================

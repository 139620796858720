import api from "../../api/axios";

export default async function createProduct(data) {
  try {
    const {
      label,
      price,
      image,
      store_id,
      productType,
      shipping_methods,
      accept_pix,
      accept_credit_card,
      accept_bank_slip,
    } = data;

    const response = await api.post("products", {
      label,
      price: `${price}`,
      description: "",
      image,
      store_id,
      isDigital: productType === "digital" ? true : false,
      shipping_methods,
      accept_pix,
      accept_credit_card,
      accept_bank_slip,
    });

    if (response.status === 201) {
      return "ok";
    }
  } catch (error) {}
}

import React, { useState } from "react";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Col, Input, InputNumber, Table, Typography } from "antd";
import Button from "../../../components/Button";
import NoDataIMG from "../../../assets/images/undraw-no-data.svg";

const { Text } = Typography;

export default function ShippingMethods({
  shippingMethods,
  setShippingMethods,
}) {
  const [label, setLabel] = useState("");
  const [price, setPrice] = useState();
  const [minDays, setMinDays] = useState("");
  const [maxDays, setMaxDays] = useState("");

  const handleSaveShippingMethod = () => {
    setShippingMethods([
      ...shippingMethods,
      {
        label,
        price: price === 0 || price === "" ? 0 : price,
        estimated_shipping_time: `De ${minDays} à ${maxDays} dias úteis`,
      },
    ]);
    setLabel("");
    setPrice("");
    setMinDays("");
    setMaxDays("");
  };

  const handleRemoveMethod = (label) => {
    const novoArray = shippingMethods.filter(
      (method) => method.label !== label
    );
    setShippingMethods(novoArray);
  };

  return (
    <Col span={24}>
      <Card title="Formas de envio">
        <div
          style={{
            display: "flex",
            gap: "24px",
            alignItems: "flex-end",
            marginBottom: 42,
          }}
        >
          <div>
            <Text strong>Nome</Text>
            <Input
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              placeholder="Ex.: Express, Econômica ou Grátis"
            />
          </div>

          <div>
            <Text strong>Preço</Text>

            <InputNumber
              value={price}
              onChange={(value) => setPrice(value)}
              size="large"
              style={{ width: "100%" }}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            />
          </div>

          <div>
            <Text strong>Min. dias úteis</Text>

            <InputNumber
              value={minDays}
              onChange={(value) => setMinDays(value)}
              size="large"
              placeholder="Min. Dias"
              style={{ width: "100%" }}
            />
          </div>

          <div>
            <Text strong>Max. dias úteis</Text>

            <InputNumber
              value={maxDays}
              onChange={(value) => setMaxDays(value)}
              size="large"
              placeholder="Max. Dias"
              style={{ width: "100%" }}
            />
          </div>

          <Button onClick={handleSaveShippingMethod} icon={<PlusOutlined />}>
            Adicionar forma de envio
          </Button>
        </div>

        {shippingMethods?.length > 0 ? (
          <Table
            pagination={false}
            dataSource={shippingMethods}
            columns={[
              {
                key: "nome",
                title: "Nome",
                dataIndex: "label",
                align: "left",
              },
              {
                key: "preco",
                title: "Preço",
                dataIndex: "price",
                align: "left",
                render: (data) =>
                  data.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  }),
              },
              {
                key: "estimated",
                title: "Prazo estimado de entrega",
                dataIndex: "estimated_shipping_time",
                align: "left",
              },
              {
                key: "remover",
                title: "Remover",
                align: "center",
                render: (data) => (
                  <DeleteOutlined
                    onClick={() => handleRemoveMethod(data.label)}
                    style={{ color: "red", cursor: "pointer" }}
                  />
                ),
              },
            ]}
          />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 24,
            }}
          >
            <img src={NoDataIMG} alt="no data" height={84} />
            <strong>Nenhuma forma de envio adicionada...</strong>
          </div>
        )}
      </Card>
    </Col>
  );
}

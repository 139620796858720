import React, { useState } from "react";
import { Col, Form, Input, InputNumber, Row, Typography } from "antd";
import Button from "../../../components/Button";
import PropTypes from "prop-types";

const { Text, Title } = Typography;

export default function ConfigurarEmail({
  newLojaInfo,
  setNewLojaInfo,
  handleCancelButtonClick,
  handleClickBackButton,
  handleNextButtonClick,
}) {
  const onFinish = (values) => {
    try {
      setNewLojaInfo({
        ...newLojaInfo,
        email_smtp: values.email_smtp,
        password_smtp: values.password_smtp,
        host_smtp: values.host_smtp,
        port_smtp: values.port_smtp,
      });
    } catch (error) {
      console.log(error.message);
    } finally {
      handleNextButtonClick();
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };

  return (
    <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <Row gutter={[24, 32]} style={{ margin: "62px auto 0" }}>
        <Col span={24} style={{ lineHeight: "1" }}>
          <Title level={5}>3. Configurar e-mails automáticos da loja.</Title>
          <Text style={{ color: "#666" }}>
            Conecte o e-mail da sua loja via SMTP e personalize mensagens
            automáticas de <strong>pedido gerado</strong> e{" "}
            <strong>pagamento aprovado</strong>
          </Text>
        </Col>

        <Col span={12}>
          <Text strong>Host SMTP</Text>
          <Form.Item
            name="host_smtp"
            rules={[
              {
                required: true,
                message:
                  "O host é obrigatório para configurar o e-mail da sua loja via SMTP",
              },
            ]}
          >
            <Input placeholder="Ex.: smtp.hostinger.com" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Text strong>Porta SMTP</Text>
          <Form.Item
            name="port_smtp"
            rules={[
              {
                required: true,
                message:
                  "A porta é obrigatória para configurar o e-mail da sua loja via SMTP",
              },
            ]}
          >
            <InputNumber size="large" placeholder="Ex.: 465" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Text strong>E-mail</Text>
          <Form.Item
            name="email_smtp"
            rules={[
              {
                required: true,
                message:
                  "Informe o e-mail da loja, por exemplo: pedidos@exemplodominio.com",
              },
              {
                type: "email",
                message: "Formato de e-mail inválido",
              },
            ]}
          >
            <Input placeholder="Ex.: pedidos@seudominio.com" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Text strong>Senha</Text>
          <Form.Item
            name="password_smtp"
            rules={[
              {
                required: true,
                message:
                  "Digite a senha do e-mail informado no campo anterior.",
              },
            ]}
          >
            <Input
              type="password"
              placeholder="Digite a senha do e-mail informado."
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              padding: 14,
            }}
          >
            <div>
              <Button danger type="link" onClick={handleCancelButtonClick}>
                Cancelar
              </Button>
            </div>

            <div style={{ display: "flex", alignItems: "center", gap: 24 }}>
              <Button type="text" onClick={handleClickBackButton}>
                Voltar
              </Button>

              <Button type="link" onClick={handleNextButtonClick}>
                Pular esta etapa
              </Button>

              <Button type="primary" htmlType="submit">
                Salvar e Continuar
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

//====================> PROP TYPES <====================
ConfigurarEmail.propTypes = {
  newLojaInfo: PropTypes.object.isRequired,
  setNewLojaInfo: PropTypes.func.isRequired,
  handleCancelButtonClick: PropTypes.func.isRequired,
  handleClickBackButton: PropTypes.func.isRequired,
  handleNextButtonClick: PropTypes.func.isRequired,
};
//====================> PROP TYPES <====================

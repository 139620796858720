import React, { useEffect, useState } from "react";
import { Col, Input, Modal, Row, Typography, Switch } from "antd";
import updateCheckoutConfigs from "../../../services/lojas/updateCheckoutConfigs.service";
import toast from "react-hot-toast";

const { Text } = Typography;

export default function EstilosDoCheckout({ isOpen, handleClose, store }) {
  const [isLoading, setIsLoading] = useState(false);
  const [checkoutConfigs, setCheckoutConfigs] = useState({
    main_color: "",
    header_bg_color: "",
    show_countdown: false,
  });

  useEffect(() => {
    console.log(store?.checkout_configs?.main_color);
    setCheckoutConfigs({
      main_color: store?.checkout_configs?.main_color,
      header_bg_color: store?.checkout_configs?.header_bg_color,
      show_countdown: store?.checkout_configs?.show_countdown,
    });
  }, [store]);

  const handleSave = async () => {
    try {
      setIsLoading(true);
      await updateCheckoutConfigs({
        store_id: store?.id,
        main_color: checkoutConfigs.main_color,
        header_bg_color: checkoutConfigs.header_bg_color,
        show_countdown: checkoutConfigs.show_countdown,
      });
      toast.success("Estilos do checkout atualizados com sucesso!");
      handleClose();
    } catch (error) {
      toast.error("Erro ao tentar atualizar estilos do checkout!");
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeColor = (e) => {
    setCheckoutConfigs({ ...checkoutConfigs, [e.target.name]: e.target.value });
  };

  const handleChangeCountdown = (value) => {
    setCheckoutConfigs({ ...checkoutConfigs, show_countdown: value });
  };

  return (
    <Modal
      visible={isOpen}
      title="Estilos do checkout"
      okText="Salvar"
      okButtonProps={{
        loading: isLoading,
      }}
      cancelText="Cancelar"
      onCancel={handleClose}
      onOk={handleSave}
    >
      <Row gutter={[24, 42]}>
        <Col span={12}>
          <Text strong>Cor principal</Text>
          <br />
          <Input
            style={{ height: 42 }}
            name="main_color"
            type="color"
            value={checkoutConfigs.main_color}
            onChange={handleChangeColor}
          />
        </Col>

        <Col span={12}>
          <Text strong>Cor do header</Text>
          <br />
          <Input
            style={{ height: 42 }}
            name="header_bg_color"
            type="color"
            value={checkoutConfigs.header_bg_color}
            onChange={handleChangeColor}
          />
        </Col>

        <Col span={24}>
          <Text strong>Mostrar contador?</Text>
          <br />
          <Switch
            size="default"
            checkedChildren="Sim"
            unCheckedChildren="Não"
            checked={checkoutConfigs.show_countdown}
            onChange={handleChangeCountdown}
          />
        </Col>
      </Row>
    </Modal>
  );
}

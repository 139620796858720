import api from "../../api/axios";

export default async function adicionarPixel({
  code,
  product_id,
  access_token,
}) {
  try {
    const response = await api.post("pixel-code", {
      code,
      access_token,
      product_id,
    });

    if (response.status === 201) {
      return "ok";
    }
  } catch (error) {}
}

import React, { useEffect, useState } from "react";
import { Col, Input, Modal, Row, Select, Typography } from "antd";
import Button from "../../../components/Button";
import { DeleteOutlined } from "@ant-design/icons";
import { MaskedInput } from "antd-mask-input";
import trocarChavePix from "../../../services/lojas/trocarChavePix.service";
import toast from "react-hot-toast";

const { Text } = Typography;

export default function TrocarChavePix({
  isOpen,
  handleClose,
  store,
  confirmDelete,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [pixKey, setPixKey] = useState({
    pix_key_type: "",
    pix_key: "",
  });

  useEffect(() => {
    console.log(store);
    setPixKey({
      pix_key_type: store?.pix_key_type,
      pix_key: store?.pix_key,
    });
  }, [store]);

  const handleChangePixKeyType = (option) => {
    setPixKey({ pix_key_type: option, pix_key: "" });
  };

  const handleChangePixKey = (e) => {
    setPixKey({ ...pixKey, pix_key: e.target.value });
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      await trocarChavePix({
        store_id: store?.id,
        pix_key_type: pixKey.pix_key_type,
        pix_key: pixKey.pix_key,
      });
      toast.success("Chave pix atualizada com sucesso!");
      handleClose();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemovePixKey = async () => {
    try {
      setIsLoading(true);
      await trocarChavePix({
        store_id: store?.id,
        pix_key_type: null,
        pix_key: null,
      });
      toast.success("Chave pix removida com sucesso!");
      handleClose();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      visible={isOpen}
      title="Chave pix"
      okText="Salvar"
      okButtonProps={{
        loading: isLoading,
      }}
      cancelText="Cancelar"
      onCancel={handleClose}
      onOk={handleSave}
    >
      <Row gutter={[24, 42]}>
        <Col span={24}>
          <Text strong>Tipo da Chave Pix</Text>
          <br />
          <Select
            size="large"
            value={pixKey.pix_key_type}
            style={{ width: "100%" }}
            onChange={handleChangePixKeyType}
            placeholder="Selecione o tipo da chave pix"
            options={[
              {
                value: "random",
                label: "Aleatória",
              },
              {
                value: "cpf",
                label: "CPF",
              },
              {
                value: "cnpj",
                label: "CNPJ",
              },
              {
                value: "phone",
                label: "Telefone",
              },
              {
                value: "email",
                label: "E-mail",
              },
            ]}
          />
        </Col>

        <Col span={24}>
          {pixKey.pix_key_type === "random" && (
            <>
              <Text strong>Chave Pix Aleatória</Text>
              <Input
                value={pixKey.pix_key}
                onChange={handleChangePixKey}
                placeholder="Ex.: 123e4567-e89b-12d3-a456-426655440000"
              />
            </>
          )}

          {pixKey.pix_key_type === "cpf" && (
            <>
              <Text strong>Chave Pix CPF</Text>

              <MaskedInput
                value={pixKey.pix_key}
                onChange={handleChangePixKey}
                placeholder="Ex.: 123.456.789-10"
                mask="111.111.111-11"
              />
            </>
          )}

          {pixKey.pix_key_type === "cnpj" && (
            <>
              <Text strong>Chave Pix CNPJ</Text>

              <MaskedInput
                value={pixKey.pix_key}
                onChange={handleChangePixKey}
                placeholder="Ex.: 12.345.678/0001-99"
                mask="11.111.111/1111-11"
              />
            </>
          )}

          {pixKey.pix_key_type === "phone" && (
            <>
              <Text strong>Chave Pix Telefone</Text>

              <MaskedInput
                value={pixKey.pix_key}
                onChange={handleChangePixKey}
                placeholder="Ex.: (99) 9999-9999"
                mask="(11) 11111-1111"
              />
            </>
          )}

          {pixKey.pix_key_type === "email" && (
            <>
              <Text strong>Chave Pix E-mail</Text>

              <Input
                value={pixKey.pix_key}
                onChange={handleChangePixKey}
                placeholder="Ex.: fulano@gmail.com"
              />
            </>
          )}
        </Col>

        {store?.pix_key !== null && (
          <Col span={24}>
            <Button
              onClick={handleRemovePixKey}
              style={{ width: "100%" }}
              danger
              type="ghost"
              icon={<DeleteOutlined />}
            >
              Remover esta chave
            </Button>
          </Col>
        )}
      </Row>
    </Modal>
  );
}

import React, { useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { Layout, Menu, Row, Col, Typography, Form, Input, Switch } from "antd";
import Button from "../../components/Button";
import {
  TwitterOutlined,
  InstagramOutlined,
  GithubOutlined,
} from "@ant-design/icons";
import logo from "../../assets/images/black-pay-logo.jpg";
import toast from "react-hot-toast";

const { Title } = Typography;
const { Header, Footer, Content } = Layout;

export default function LoginForm() {
  const history = useHistory();
  const { signIn, isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/dashboard");
    }
  }, [isAuthenticated, history]);

  const onFinish = async (values) => {
    try {
      await signIn({ email: values.email, password: values.password });
    } catch (error) {
      toast.error(error.message, {
        position: "top-center",
        duration: 7000,
        style: {
          fontSize: "16px",
          fontWeight: 700,
          color: "#fff",
          background: "#ff4b4b",
        },
        iconTheme: {
          primary: "#fff",
          secondary: "#ff4b4b",
        },
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function onChange(checked) {
    console.log(`switch to ${checked}`);
  }

  return (
    <>
      <Layout className="layout-default layout-signin">
        <Header>
          <div className="header-col header-brand">
            <img src={logo} alt="blackpay logo" />
            <h5>BlackPay</h5>
          </div>
        </Header>
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Title style={{ fontSize: "24px" }} className="mb-15">
                Acesse sua conta
              </Title>
              <Title className="font-regular text-muted" level={5}>
                Digite seu e-mail e senha para entrar
              </Title>
              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  label="E-mail"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, digite seu e-mail!",
                    },
                    {
                      type: "email",
                      message: "Insira um e-mail válido!",
                    },
                  ]}
                >
                  <Input placeholder="E-mail" />
                </Form.Item>

                <Form.Item
                  className="username"
                  label="Senha"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, digite sua senha!",
                    },
                    {
                      min: 6,
                      message: "Sua senha deve ter pelo menos 6 caracteres!",
                    },
                  ]}
                >
                  <Input placeholder="Senha" type="password" />
                </Form.Item>

                <Form.Item
                  name="remember"
                  className="aligin-center"
                  valuePropName="checked"
                >
                  <Switch defaultChecked onChange={onChange} />
                  Lembrar-me
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" fullWidth>
                    ENTRAR
                  </Button>
                </Form.Item>
                <p className="font-semibold text-muted">
                  Ainda não possui uma conta?{" "}
                  <Link to="/sign-up" className="text-dark font-bold">
                    Comece agora
                  </Link>
                </p>
              </Form>
            </Col>
            <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <img src={logo} alt="" />
            </Col>
          </Row>
        </Content>
        <Footer>
          <Menu mode="horizontal" className="menu-nav-social">
            <Menu.Item>
              <Link to="#">{<TwitterOutlined />}</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="#">{<InstagramOutlined />}</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="#">
                <svg
                  width="18"
                  height="18"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M496 256c0 137-111 248-248 248-25.6 0-50.2-3.9-73.4-11.1 10.1-16.5 25.2-43.5 30.8-65 3-11.6 15.4-59 15.4-59 8.1 15.4 31.7 28.5 56.8 28.5 74.8 0 128.7-68.8 128.7-154.3 0-81.9-66.9-143.2-152.9-143.2-107 0-163.9 71.8-163.9 150.1 0 36.4 19.4 81.7 50.3 96.1 4.7 2.2 7.2 1.2 8.3-3.3.8-3.4 5-20.3 6.9-28.1.6-2.5.3-4.7-1.7-7.1-10.1-12.5-18.3-35.3-18.3-56.6 0-54.7 41.4-107.6 112-107.6 60.9 0 103.6 41.5 103.6 100.9 0 67.1-33.9 113.6-78 113.6-24.3 0-42.6-20.1-36.7-44.8 7-29.5 20.5-61.3 20.5-82.6 0-19-10.2-34.9-31.4-34.9-24.9 0-44.9 25.7-44.9 60.2 0 22 7.4 36.8 7.4 36.8s-24.5 103.8-29 123.2c-5 21.4-3 51.6-.9 71.2C65.4 450.9 0 361.1 0 256 0 119 111 8 248 8s248 111 248 248z"></path>
                </svg>
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="#">{<GithubOutlined />}</Link>
            </Menu.Item>
          </Menu>
          <p className="copyright">
            {" "}
            Copyright © 2023 by <a href="#pablo">#SouBlackPay</a>.{" "}
          </p>
        </Footer>
      </Layout>
    </>
  );
}
